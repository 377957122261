import React, { useEffect, useState } from 'react';
import { Backend } from '../client/backend';
import { Rating } from 'react-simple-star-rating'

import '@smastrom/react-rating/style.css'
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from 'react-datepicker'

import { useNavigate, useSearchParams } from 'react-router-dom';
import { RatingContainer } from '../component/rating';
import { ImageWithErr } from '../component/image';
import { PiNumberCircleSixDuotone, PiStar } from "react-icons/pi";
import { FaRedo, FaSearch } from 'react-icons/fa';
import { Loading } from '../util/loading';
import { Search } from '../component/search';
import moment from 'moment';
import { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";
import { RedirectIfNotSignedIn } from '../util/redirect';


const draftsEnabled = false

const AlbumCoverResult = ({ title, artist, albms_id, handleSelectCallback }: any) => {
  return (
    <div onClick={() => handleSelectCallback(title, artist, albms_id, albms_id)} className='hover:cursor-pointer flex flex-row w-full justify-around'>
      <div className='flex flex-col items-center p-2'>
        <div id='albumCoverResult' className='flex flex-row justify-around'>
          <ImageWithErr
            loading='eager'
            alt={`covert art for ${artist} - ${title}`}
            src={Backend.imageFor(albms_id)} />
        </div>
      </div>
    </div>
  )
}

const AlbumResult = ({ Name, Artist, CorrelationID, AlbmsID, selectionText, handleSelectCallback }: any) => {
  return (
    <div className='flex flex-row justify-between'>
      <div className='w-3/4 flex flex-row p-2'>
        <div className='albumResultImageContainer flex flex-col mt-2'>
          <ImageWithErr
            alt={`covert art for ${Artist} - ${Name}`}
            src={Backend.imageFor(AlbmsID)} />
        </div>

        <div className='p-2'>
          <h2 className='font-bold'>{Name}</h2>
          <h3>{Artist}</h3>
        </div>
      </div>

      <div className='flex flex-row justify-around self-center'>
        <button
          className='btn'
          onClick={() => handleSelectCallback(Name, Artist, CorrelationID, AlbmsID)}>{selectionText}</button>
      </div>
    </div>
  )
}

const ScribbleSearchWithAI = ({ title = 'What album do you want to log?', selectionText = 'Pick', setSelectedAlbumMBID, placeholder = '', initialValue = undefined, onSearchResolved = () => { } }: any) => {
  const [query, setQuery] = useState<string>(initialValue || '')

  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [precise, setPrecise] = useState(false)

  const select = (name: string, artist: string, id: any, albmsId: any) => {
    setSelectedAlbumMBID({ name, artist, id, albmsId })
  }

  const onAddAllClicked = () => {
    (results || []).forEach((result: any) => {
      select(result.Name, result.Arist, result.CorrelationID, result.AlbmsID)
    })
    setResults([])
  }

  const handleSearch = () => {
    setLoading(true)
    Backend.aiAssistedSearch(query, precise)
      .then((response: any) => {
        if (response && response.results) {
          setResults([...response.results])
        }
      })
      .finally(() => {
        setLoading(false)
        onSearchResolved()
      })
  }

  return (
    <div className='flex flex-col w-1/1 my-2'>
      <h1 className='font-bold text-xl my-2'>
        {title}
      </h1>
      <div className='flex flex-row gap-1'>
        <input
          type="text"
          placeholder={placeholder}
          className="input input-bordered input-primary w-full"
          value={query}
          onChange={(e) => setQuery(e.target.value)} />

        <button onClick={handleSearch} className='btn btn-square btn-success'>
          <FaSearch />
        </button>
      </div>
      <div>
        <div className="form-control">
          <label className="flex flex-row gap-2 my-2 items-center cursor-pointer">
            <input onClick={() => setPrecise(!precise)} type="checkbox" defaultChecked={precise} className="checkbox" />
            <span className="label-text">Precise results</span>
          </label>
        </div>
      </div>

      <div className='flex flex-col my-4'>
        {
          loading ? (
            <div className='flex flex-row justify-around my-16'>
              <Loading />
            </div>
          ) : (
            <>
              {
                (results && results.length != 0) && (
                  <div className='flex flex-row items-center justify-between'>
                    <h3 className='text-xl font-bold'>Results</h3>
                    <button className='btn btn-sm btn-primary' onClick={onAddAllClicked}>Add All</button>
                  </div>
                )
              }
              {
                results && (
                  results
                    .filter((album: any) => album.CorrelationID)
                    .map((album: any) =>
                      <AlbumResult
                        {...album}
                        selectionText={selectionText}
                        key={`album-${album.CorrelationID}`}
                        handleSelectCallback={select} />)
                )
              }
            </>
          )
        }
      </div>
    </div>
  )
}

const DraftResult = ({ id }) => {
  const [result, setResult] = useState<any>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Backend.getAlbumInfo(id)
      .then((obj) => {
        if (obj) {
          setResult(obj)
          setLoading(false)
        }
      })
  }, [id])

  if (loading) {
    return (
      <></>
    )
  }

  return (
    <AlbumResult {...result} />
  )
}

const LoadFromDraft = () => {
  const [drafts, setDrafts] = useState<any>([])
  const [profile, setProfile] = useState<any>(undefined)

  const keys = Object.keys(localStorage).filter((k) => k.startsWith("rc"))

  useEffect(() => {
    Backend.myStats()
      .then((obj) => setProfile(obj))
  }, [])

  useEffect(() => {
    const currentUser = profile.username
    if (currentUser) {
      const draftsForCurrentUser = keys
        .map((key: any) => {
          const [_, albmsID, user] = key.split(':')
          return [albmsID, user]
        })
        .filter(([, user]) => user == currentUser)
        .map(([albmsID]) => albmsID)
        .filter((id) => id)

      setDrafts(draftsForCurrentUser)
    }
  }, [profile])

  return (
    <div>
      <div>
        <h3 className='text-xl font-bold'>Pick up where you left off</h3>
        <p>
          We noticed you have some drafts in local storage.
        </p>

        {
          drafts.map((id) => <DraftResult id={id} />)
        }
      </div>
      <div>

      </div>
    </div>
  )
}

const ScribbleSearch = ({ title = 'What album do you want to log?', selectionText = 'Pick', setSelectedAlbumMBID, tooltip = true, inspiration = true, initSearchVal = undefined, placeholder = '' }: any) => {
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery, setDebouncedQuery] = useState<string>('')

  const [inspoResults, setInspoResults] = useState<any>([])
  const [inspoLoading, setInspoLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [cap, setCap] = useState(3)

  useEffect(() => {
    setQuery(initSearchVal)
    setDebouncedQuery(initSearchVal)
  }, [initSearchVal])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedQuery(query);
    }, 125);

    return () => {
      clearTimeout(timerId);
    };
  }, [query]);

  useEffect(() => {
    if (debouncedQuery && debouncedQuery !== '' && debouncedQuery.length >= 3) {
      setSearchParams({ ['q']: debouncedQuery })

      setLoading(true)
      Backend.search(debouncedQuery)
        .then((response: any) => {
          if (response && response.Results) {
            setCap(3)
            setResults([...response.Results])
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [debouncedQuery])

  const shuffle = () => {
    setInspoLoading(true)
    Backend.shuffle()
      .then((results: any) => {
        if (results && results?.Results) {
          setInspoResults([...results.Results])
        }
      })
      .finally(() => {
        setInspoLoading(false)
      })
  }

  useEffect(() => {
    shuffle()
  }, [])

  const select = (name: string, artist: string, id: any, albmsId: any) => {
    setSelectedAlbumMBID({ name, artist, id, albmsId })
  }

  return (
    <div className='flex flex-col w-1/1 my-4'>
      <h1 className='font-bold text-xl my-2'>
        {title}
      </h1>
      <div className='flex flex-col'>
        <div className='flex flex-row'>
          <input
            type="text"
            placeholder={placeholder}
            className="input input-bordered input-primary w-full"
            value={query}
            onChange={(e) => setQuery(e.target.value)} />
        </div>
        <div className='flex flex-row justify-between'>
          {
            tooltip && (
              <div className='my-2 flex flex-row items-center'>
                <p className='text-xs'>
                  {tooltip}
                </p>
              </div>
            )
          }

          <div className='flex flex-row gap-1 my-1 mx-1 items-center justify-end'>
            <p className='w-full text-xs italic text-gray-200'>Search by Algolia</p>
            <img className='w-4' src='/algolia.png' />
          </div>
        </div>
      </div>

      <div className='flex flex-col my-4'>
        {
          loading ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              {
                results && (
                  results
                    .slice(0, Math.min(cap, results.length))
                    .filter((album: any) => album.CorrelationID)
                    .map((album: any) =>
                      <AlbumResult
                        {...album}
                        selectionText={selectionText}
                        key={`album-${album.CorrelationID}`}
                        handleSelectCallback={select} />)
                )
              }
              {(results.length > 0 && cap < results.length) && <div className='flex flex-row justify-around'>
                <button onClick={() => setCap(cap + 3)} className='btn btn-xs btn-ghost btn-outline'>Show More</button>
              </div>}
            </>
          )
        }
      </div>

      {
        draftsEnabled &&
        (Object.keys(localStorage).filter((k) => k.startsWith("rc")).length != 0) && (
          <LoadFromDraft />
        )
      }

      {
        inspiration && (
          <div>
            <div className='flex flex-row justify-between items-center'>
              <div>
                <h3 className='text-xl font-bold'>Need some inspiration?</h3>
                <p>
                  How about one of these
                </p>
              </div>

              <div>
                <button onClick={shuffle} className='btn btn-square btn-success text-xl'><FaRedo /></button>
              </div>
            </div>

            <div className='flex flex-row justify-around w-full my-4'>
              {
                inspoLoading ? (
                  <div className='flex flex-col p-8 m-8'>
                    <Loading />
                  </div>
                ) : (
                  <div className='grid grid-cols-3 gap-4 my-4'>
                    {inspoResults && inspoResults
                      .slice(0, 6)
                      .map((album: any) => {
                        return <AlbumCoverResult
                          {...album}
                          small
                          key={`ar-${album.albms_id}`}
                          handleSelectCallback={select} />
                      })
                    }
                  </div>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  )
}


const StarRatingInput = ({ rating, setRating, size = 64 }) => {
  return (
    <>
      <div className='flex flex-row justify-around'>
        <Rating
          size={size}
          allowFraction
          initialValue={rating / 2}
          onClick={(r: any) => setRating(r * 2)}
          SVGclassName={`inline-block`} />
      </div>
    </>
  )
}

const NumericRatingInput = ({ rating, setRating }) => {
  return (
    <>
      <div className='flex flex-row justify-around my-4'>
        <RatingContainer large rating={rating} />
      </div>

      <div className='flex flex-col'>
        <input type="range" min={0} max={10.0} value={rating} step={0.1} onChange={(e: any) => setRating(parseFloat(e.target.value))} className="range range-primary" />
        <div className="w-full flex justify-between text-xs px-2">
          <div className='flex flex-col justify-around items-center'>
            <span>|</span>
            <span>0.0</span>
          </div>
          <div className='flex flex-col justify-around items-center'>
            <span>|</span>
            <span>5.0</span>
          </div>
          <div className='flex flex-col justify-around items-center'>
            <span>|</span>
            <span>10.0</span>
          </div>
        </div>
      </div>
    </>
  )
}

const NewEntry = () => {
  const [profile, setProfile] = useState<any>(undefined)
  const [searchParams] = useSearchParams()

  const [ratingSystem, setRatingSystem] = useState(localStorage.getItem('_ratingSystem') || 'STAR')

  const [selectedAlbum, setSelectedAlbum] = useState<any>(null)
  const [selectedAlbumMBID, setSelectedAlbumMBID] = useState<any>(undefined)
  const [writtenReview, setWrittenReview] = useState<any>(undefined)
  const [isFavourite, setIsFavourite] = useState(false)
  const [listenedAtDate, setListenedAtDate] = useState(new Date());
  const [heardBefore, setIsRelisten] = useState(false)
  const navigate = useNavigate()
  const [rating, setRating] = useState(0)
  const [isReview, setIsReview] = useState(false)
  const [hasBeenRated, setHasBeenRated] = useState(false)
  const [errorMsg, setErrorMsg] = useState<any>(undefined)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    Backend.myStats()
      .then((obj) => {
        setProfile(obj)
      })
  }, [])

  useEffect(() => {
    if (searchParams.has('hash')) {
      const hash = searchParams.get('hash')
      Backend.getInfo(hash).then((obj) => {
        setSelectedAlbum(obj)
      })
    }
    document.title = 'albms.net - submit'
  }, [searchParams])

  useEffect(() => {
    if (selectedAlbum && profile && writtenReview) {
      const key = `rc:${selectedAlbum.albms_id}:${profile.username}`
      localStorage.setItem(key, writtenReview)
    }
  }, [selectedAlbum, profile, writtenReview])

  useEffect(() => {
    if (selectedAlbum && profile) {
      Backend.getAlbumRating(selectedAlbum.albms_id, profile.username)
        .then((obj: any) => {
          if (obj && obj.rating) {
            setRating(obj.rating)
            setHasBeenRated(true)
            // TODO inform user
          }
        })
    }
  }, [selectedAlbum, profile])

  useEffect(() => {
    if (selectedAlbumMBID) {
      const id = selectedAlbumMBID.albmsId
      Backend.getInfo(id).then((obj) => {
        setSelectedAlbum(obj)
      })
    }
  }, [selectedAlbumMBID])

  const onContinueReview = () => {
    // store in structured way so we can pick up drafts more easily.
    if (selectedAlbum && profile) {
      const content = localStorage.getItem(`rc:${selectedAlbum.albms_id}:${profile.username}`)
      if (content) {
        setWrittenReview(content)
      }
    }
    setIsReview(true)
  }

  const toggleRatingSystem = () => {
    if (ratingSystem === 'NUMERIC') {
      localStorage.setItem('_ratingSystem', 'STAR')
      setRatingSystem('STAR')
    } else {
      localStorage.setItem('_ratingSystem', 'NUMERIC')
      setRatingSystem('NUMERIC')
    }
  }

  const handleSubmitRating = () => {
    if (!selectedAlbum || submitting) return
    setSubmitting(true)

    Backend.submitRating({
      album_title: selectedAlbum.title,
      artist_name: selectedAlbum.artist,
      rating: rating,
    })
      .then((obj) => {
        if (!obj.ok) {
          setSubmitting(false)
          setErrorMsg('Something went wrong. Please try again later!')
          return
        }

        // handle errs, redirects, etc.
        setSubmitting(false)
        navigate(`/ratingConfirmation`)
      })
  }

  const handleSubmitReview = () => {
    setSubmitting(true)

    // we're trying to say we listened to this in the future!
    // do nothing.
    if (moment(listenedAtDate).isAfter(moment())) {
      setErrorMsg('Nice try time traveller. Set your listened at date to before now.')
      setSubmitting(false)
      return
    }

    if (selectedAlbum && profile && !submitting) {
      Backend.submitAlbumNote({
        album_title: selectedAlbum.title,
        artist_name: selectedAlbum.artist,
        rating: rating,
        is_favourite: isFavourite,
        is_review: false,
        is_relisten: heardBefore,
        content: writtenReview,
        listened_at: listenedAtDate,
      })
        .then((obj) => {
          if (obj) {
            // drop the draft as it's been submitted
            const key = `rc:${selectedAlbum.albms_id}:${profile.username}`
            localStorage.removeItem(key)

            setSubmitting(false)
          }

          navigate(`/reviewConfirmation`)
        })
        .catch((err) => {
          console.error(err)
          setErrorMsg('Something went wrong when posting your review. Please try again later! Your review has been saved to this device.')
          setSubmitting(false)
        })
    }
  }

  return (
    <>
      <div className='mx-4'>
        <div className='flex flex-col justify-around my-2 mb-4'>
          <h1 className="text-2xl font-bold">
            Write a review
          </h1>
          <p>
            Reviews are posted on your profile and are featured on the front page of albms.net.
          </p>
        </div>
      </div>

      {selectedAlbum ?
        (
          <div className='m-4 flex flex-row justify-between'>
            <div className='flex flex-row gap-2 items-center'>
              <div className='w-14'>
                <ImageWithErr src={Backend.imageFor(selectedAlbum.albms_id)} />
              </div>
              <div>
                <h2 className="text-lg font-bold">{selectedAlbum.title}</h2>
                <h3 className="text-sm">{selectedAlbum.artist}</h3>
              </div>
            </div>
            <div className='flex flex-row items-center'>
              <span onClick={toggleRatingSystem} className='text-4xl'>
                {
                  ratingSystem === 'NUMERIC'
                    ? <PiStar />
                    : <PiNumberCircleSixDuotone />
                }
              </span>
            </div>
          </div>
        )
        :
        <div className='mx-4'>
          <Search tooltip='You can either review an album or log it.'
            setSelectedAlbumMBID={setSelectedAlbumMBID} />
        </div>
      }

      {
        selectedAlbum && (
          <div className='m-4'>
            <div className='flex flex-col justify-around my-8'>
              {
                ratingSystem === 'NUMERIC'
                  ? <NumericRatingInput rating={rating} setRating={setRating} />
                  : <StarRatingInput rating={rating} setRating={setRating} />
              }
            </div>

            {
              !isReview && (
                <div className='flex flex-col items-center gap-2 justify-around'>
                  <div className='flex flex-row gap-2 my-4'>
                    <button disabled={submitting} onClick={handleSubmitRating} className='btn'>
                      {
                        submitting ? (
                          <>Submitting <Loading size={'small'} /></>
                        ) : (
                          <>{hasBeenRated ? 'Update Rating' : 'Submit Rating'}</>
                        )
                      }
                    </button>

                    <button onClick={onContinueReview} className='btn btn-success'>
                      Continue as Review
                    </button>
                  </div>

                  <div>
                    <button onClick={() => {
                      setSelectedAlbum(undefined)
                    }} className='btn btn-xs btn-ghost'>Pick something else</button>
                  </div>
                </div>
              )
            }

            {
              isReview && (
                <>
                  <div className='my-8'>
                    <div className='grid grid-cols-2'>
                      <div className="form-control">
                        <label className="flex flex-row gap-1 my-2 items-center cursor-pointer">
                          <input onClick={() => setIsRelisten(!heardBefore)} type="checkbox" defaultChecked={heardBefore} className="checkbox" />
                          <span className="label-text">I've heard this before</span>
                        </label>
                      </div>

                      <div className="form-control">
                        <label className="flex flex-row gap-1 my-2 items-center cursor-pointer">
                          <input onClick={() => setIsFavourite(!isFavourite)} type="checkbox" defaultChecked={isFavourite} className="checkbox" />
                          <span className="label-text">Mark as favourite</span>
                        </label>
                      </div>
                    </div>

                    <div className='flex flex-col my-4'>
                      <label className='font-bold'>When did you listen to this album?</label>
                      <div>
                        <DatePicker
                          selected={listenedAtDate}
                          onChange={(date) => setListenedAtDate(date)} />
                      </div>
                    </div>
                  </div>

                  <div className='my-2'>
                    <h3 className='text-lg font-bold'>
                      Write something
                    </h3>

                    <div className="form-control">
                      <textarea
                        value={writtenReview}
                        className="textarea textarea-bordered h-32 w-full"
                        onChange={(e: any) => ((writtenReview || '').length < 5000) ? setWrittenReview(e.target.value) : {}}
                        placeholder="This was ..."></textarea>

                      <label className="label">
                        <span className="label-text-alt">{((writtenReview || '').length)}/5000 characters</span>
                      </label>
                    </div>
                  </div>

                  <div className='mb-8'>
                    <div className='flex flex-row justify-between my-2'>
                      <div className='w-full md:w-1/2 flex flex-row text-xs text-red-500 px-4 py-2'>
                        <p>
                          {errorMsg}
                        </p>
                      </div>

                      <button disabled={submitting} onClick={handleSubmitReview} className='btn btn-success'>
                        {
                          submitting ? (
                            <>Submitting <Loading size={'small'} /></>
                          ) : (
                            <>Submit Review</>
                          )
                        }

                      </button>
                    </div>
                  </div>
                </>
              )
            }
          </div>
        )
      }
    </>
  )
}

function Submit() {
  return (
    <>
      <RedirectIfNotSignedIn>
        <NewEntry />
      </RedirectIfNotSignedIn>
    </>
  );
}

export {
  Submit,
  ScribbleSearch,
  ScribbleSearchWithAI,
  NumericRatingInput,
  StarRatingInput
}
