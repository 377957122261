import { useState } from "react"

interface ImageWithErrProps {
  src: any
  alt?: string
  errSrc?: string
  classNames?: string
  loading?: 'lazy' | 'eager'
}

const ImageWithErr = ({ src, alt, errSrc = 'https://via.placeholder.com/174x174?text=No%20Image', classNames = '', loading = 'lazy' }: ImageWithErrProps) => {
  const [imageSrc, setSrc] = useState(src)
  const [error, setError] = useState(false)

  const handleImageError = (e: any) => {
    if (!error) {
      setSrc(errSrc)
      setError(true)
    }
  }

  return (
    <img
      loading={loading}
      className={`rounded-lg max-w-full h-auto shadow-md ${classNames}`}
      src={imageSrc}
      onError={handleImageError}
      alt={alt} />
  )
}

export {
  ImageWithErr
}