import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Backend, UpdateAlbumReview } from '../client/backend'
import { RatingContainer } from '../component/rating'
import moment from 'moment'
import { ImageWithErr } from '../component/image'
import { FaBars, FaShareAlt, FaThumbtack } from 'react-icons/fa'
import { useAuth } from '@clerk/clerk-react'
import { Rating } from 'react-simple-star-rating'
import { ProfileBadge } from '../component/profile_badge'
import { Loading } from '../util/loading'
import { NumericRatingInput, StarRatingInput } from './submit_page'
import { LinkTo } from './list'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker'
import { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";


const favsEnabled = false

const ReviewEditPane = ({ onArchiveClicked, onEditClicked }) => {
  return (
    <div className="dropdown dropdown-end">
      <div tabIndex={0} role="button" className="btn btn-square btn-sm m-1"><FaBars /></div>
      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box">
        <li onClick={onEditClicked}><a>Edit</a></li>
      </ul>
    </div>
  )
}

export const ProUpsell = () => {
  return (
    <>
      Upgrade to <LinkTo to='/pro' value={'PRO or Patron'} /> for longer reviews.
    </>
  )
}

const EditReviewModal = ({ reviewId, initRating, initReview, reloadReview, isPinned,
  isFavourite, isArchived, listenedAt }) => {
  const [ratingSystem, setRatingSystem] = useState(localStorage.getItem('_ratingSystem') || 'STAR')
  const [rating, setRating] = useState(initRating)
  const [writtenReview, setWrittenReview] = useState(initReview)

  // todo grab from stats.
  const [heardBefore, setIsRelisten] = useState(false)
  const [favourite, setIsFavourite] = useState(isFavourite)
  const [pinned, setIsPinned] = useState(isPinned)
  const [archived, setArchived] = useState(isArchived)
  const [listenedAtDate, setListenedAtDate] = useState(new Date());

  // FIXME(FELIX): allow listened at to be changed
  // const [newListenedAt, setListenedAt] = useState(listenedAt)

  const onSaveChanges = () => {
    Backend.updateReview({
      id: reviewId,
      content: writtenReview,
      rating: rating,
      is_pinned: pinned,
      is_favourite: favourite,
      is_archived: archived,
      listened_at: listenedAtDate,
    } as UpdateAlbumReview)
    reloadReview()
  }

  return (
    <div className="modal-box">
      <h3 className="font-bold text-2xl">Edit your review</h3>
      <div className='my-2'>
        <h3 className='text-lg font-bold'>
          Write something
        </h3>

        <div className="form-control">
          <textarea
            value={writtenReview}
            className="textarea textarea-bordered h-32 w-full"
            onChange={(e: any) => ((writtenReview || '').length < 5000) ? setWrittenReview(e.target.value) : {}}
            placeholder="This was ..."></textarea>

          <label className="label">
            <span className="label-text-alt">{((writtenReview || '').length)}/5000 characters. <ProUpsell /></span>
          </label>
        </div>
      </div>

      <div>
        <h3 className='text-lg font-bold'>
          Adjust your rating
        </h3>

        <div className='flex flex-col justify-around my-4 mb-8'>
          {
            ratingSystem === 'NUMERIC'
              ? <NumericRatingInput rating={rating} setRating={setRating} />
              : <StarRatingInput rating={rating} setRating={setRating} />
          }
        </div>
      </div>

      <h3 className='font-bold text-lg'>Additional details</h3>

      <div className='grid grid-cols-2 gap-4'>
        <div>
          <div className="form-control">
            <label className="flex flex-row gap-2 my-2 items-center cursor-pointer">
              <input onClick={() => setIsRelisten(!heardBefore)} type="checkbox" defaultChecked={heardBefore} className="checkbox" />
              <span className="label-text">I've heard this before</span>
            </label>
          </div>
        </div>

        <div>
          <div className="form-control">
            <label className="flex flex-row gap-2 my-2 items-center cursor-pointer">
              <input onClick={() => setIsFavourite(!favourite)} type="checkbox" defaultChecked={favourite} className="checkbox" />
              <span className="label-text">Mark as favourite</span>
            </label>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-2 gap-4'>
        <div>
          <div className="form-control">
            <label className="flex flex-row gap-2 my-2 items-center cursor-pointer">
              <input onClick={() => setIsPinned(!pinned)} type="checkbox" defaultChecked={pinned} className="checkbox" />
              <span className="label-text">Pinned to profile</span>
            </label>
          </div>
        </div>

        <div>
          <div className="form-control">
            <label className="flex flex-row gap-2 my-2 items-center cursor-pointer">
              <input onClick={() => setArchived(!archived)} type="checkbox" defaultChecked={archived} className="checkbox" />
              <span className="label-text">Archived</span>
            </label>
          </div>
        </div>

        <div className='flex flex-col my-4'>
          <label className='font-bold'>When did you listen to this album?</label>
          <div>
            <DatePicker
              selected={listenedAtDate}
              onChange={(date) => setListenedAtDate(date)} />
          </div>
        </div>
      </div>

      {archived && <p className='text-xs text-gray-500'>
        Archived reviews are not present on your profile or
        to others around the website. You can un-archive a review at any time. <span className='font-bold'>Archived reviews will be deleted after 30 days.</span>
      </p>}

      <div className="modal-action">
        <form className='flex flex-row justify-between w-full items-center' method="dialog">
          <button className="btn btn-xs">Close without saving</button>
          <button onClick={onSaveChanges} className="btn btn-success">Save Changes</button>
        </form>
      </div>
    </div>
  )
}

const ReviewContainer = ({ id, withEditControl = true }) => {
  const navigate = useNavigate()

  const session = useSessionContext() as any
  const [response, setResponse] = useState<any>()
  const [stats, setStats] = useState({
    favoritesCount: -1,
    isFavorite: false,
    scope: 'READ',
  })

  const reloadStats = () => {
    if (id) {
      Backend.getFeedItemStats(id)
        .then((response) => response.json())
        .then((obj: any) => {
          setStats(obj)
        })
    }
  }

  const reloadFeedItem = () => {
    if (id) {
      Backend.getFeedItem(id)
        .then((response: any) => response.json())
        .then((obj: any) => {
          setResponse(obj)
          return obj;
        })
    }
  }

  useEffect(() => {
    if (id) {
      reloadStats()
      reloadFeedItem()
    }
  }, [id])


  if (!response) {
    return (
      <>
        <div className='flex flex-row w-full justify-around my-64 text-gray-600'>
          <div className='flex flex-col items-center gap-2'>
            <span className='text-4xl'>
              <Loading />
            </span>
          </div>
        </div>
      </>
    )
  }

  const shareReview = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Share this review on albms.net',
        url: window.location.href,
        text: `Check out this review of ${response.album_title} on albms.net`
      }).then(() => { })
        .catch(console.error);
    }
  }

  const onEditClicked = () => {
    (document.getElementById('editReviewModal') as any).showModal()
  }

  const onArchiveClicked = () => {
    if (window.confirm('Archive this review?')) {
      Backend.updateReview({
        id: id,
        is_archived: true,
      })
        .then((obj: any) => {
          navigate('/')
        })
    }
  }

  return (
    <>
      <div className='flex flex-col justify-around m-4'>
        <div className='flex flex-row justify-between mb-6'>
          <div>
            <h2 className='flex flex-row text-3xl font-bold'>{response.album_title}
              {
                ((response?.is_pinned && response?.author_username) || false) && (
                  <span data-tip={`Pinned by ${response.author_username}`} className='tooltip tooltip-bottom tooltip-xs text-blue-500 mx-1'>
                    <div className='text-lg rotate-45'><FaThumbtack /></div>
                  </span>
                )
              }
            </h2>
            <h3 className='text-xl'>{response.artist_name}</h3>
          </div>

          {session.doesSessionExist && ((stats?.scope || 'READ') === 'WRITE') && (withEditControl) && (
            <ReviewEditPane onEditClicked={onEditClicked} onArchiveClicked={onArchiveClicked} />
          )}
        </div>

        <div className='dropdown flex flex-row gap-2 my-4 justify-around items-center'>
          <div className='flex flex-col justify-end w-1/2'>
            <div className='self-center'>
              <ImageWithErr src={Backend.imageFor(response.albms_id)} />
              <div className='flex flex-row justify-around'>
                <div style={{ marginTop: '-16px' }} tabIndex={0} role='button' className='btn bg-white text-black shadow-lg btn-circle btn-sm'>
                  <FaBars />
                </div>
              </div>

              <ul tabIndex={0} className="mt-1 dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box">
                <li><a onClick={() => {
                  navigate(`/submit?hash=${response.albms_id}`)
                }}>Log this album</a></li>
                <li><a onClick={() => navigate(`/view/album/${response.albms_id}`)}>View album</a></li>
              </ul>
            </div>
          </div>

          {
            (localStorage.getItem('_ratingSystem') || 'NUMERIC') === 'NUMERIC' ?
              (
                <div className='flex flex-row justify-start w-1/3'>
                  <RatingContainer large rating={response.rating} />
                </div>
              )
              :
              (
                <div className='flex flex-row justify-around w-1/2'>
                  <div className='flex flex-col'>
                    <div className='flex flex-row justify-around'>
                      <span className='text-3xl font-bold'>
                        {(response.rating / 2).toFixed(1)} / 5
                      </span>
                    </div>
                    <div className='flex flex-row justify-around'>
                      <Rating
                        size={24}
                        SVGclassName='inline-block'
                        initialValue={response.rating / 2}
                        allowFraction
                        readonly />
                    </div>
                  </div>
                </div>
              )
          }
        </div>
      </div>

      <div>
        <div className='flex flex-row justify-between p-4 mx-4 rounded bg-neutral'>
          <div className='my-2'>
            <ProfileBadge username={response.author_username} />
            <div data-tip={`Listened to on the ${moment(response.listened_at).format('Do MMMM, YYYY')}.`} className='flex flex-col tooltip tooltip-bottom tooltip-xs mx-1'>
              <p className='text-xs'>Posted {moment(response.created_at).fromNow()}. {((moment(response.listened_at).add(1, 'hour')).isBefore(moment(response.created_at))) && <>Listened to {moment(response.listened_at).fromNow()}.</>}</p>
            </div>
          </div>

          <div className='flex flex-row gap-2 justify-around items-center text-2xl'>
            {
              ((navigator as any).share) && <div className='flex flex-col items-center gap-1'>
                <button onClick={shareReview} className='text-blue-500 text-sm'>
                  <FaShareAlt />
                </button>
                <span className='font-bold text-xs'>Share</span>
              </div>
            }
          </div>
        </div>

        <div className='m-8'>
          {
            response.content ? (
              <div>
                <p id='reviewContent'>{(response.content || '')}</p>
              </div>
            ) : (
              <span className='text-sm'>No written review was given for this album.</span>
            )
          }
        </div>
      </div>

      {withEditControl && <dialog id="editReviewModal" className="modal modal-bottom sm:modal-middle">
        <EditReviewModal
          reloadReview={reloadFeedItem}
          isPinned={response.is_pinned}
          isFavourite={response.is_favourite}
          isArchived={response.is_archived}
          listenedAt={response.listened_at}
          reviewId={response.external_id}
          initRating={response.rating}
          initReview={response.content} />
      </dialog>}
    </>
  )
}

const ReviewPage = () => {
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      // track event for individual feed load.
    }
  }, [id])

  return (
    <>
      <ReviewContainer id={id} />
    </>
  )
}

export {
  ReviewPage,
  ReviewContainer
}