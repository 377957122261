
import { useEffect, useState } from "react"
import { Backend } from "../client/backend"
import { SignInButton, useAuth, useUser } from "@clerk/clerk-react"
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { centerCrop, makeAspectCrop, type Crop } from 'react-image-crop'
import { FeedItem } from "../component/feed_items"
import { ResultsContainer } from "../component/favourites_container"
import { FaArrowRight, FaCalendarAlt, FaFire, FaLink, FaLocationArrow, FaPencilAlt, FaStar, FaThumbtack } from "react-icons/fa"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { ListItemThumbnail } from "./view_list_page"
import { sliceContent } from "../util/random"
import { PatronBadge, PopulatedProfileBadge, ProBadge, ProfileBadge } from "../component/profile_badge"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { LinkTo } from "./list"
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { Loading } from "../util/loading"


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const RatingHistogram = ({ data }) => {
  const [curr, setCurr] = useState<string>(undefined)

  const isStar = localStorage.getItem('_ratingSystem') == 'STAR'
  const max = isStar ? 5 : 10

  const offs = 0
  const containerHeight = 64 // how can we scale this...
  const maxVal: any = data ? (Object.values(data) || []).sort().reverse()[0] : 1
  const maxHeight = Math.max(maxVal, containerHeight)

  // scale to fit NEAR max height of the container
  // FIXME(FELIX): this sucks
  const scale = (containerHeight / maxHeight) * .4

  return (
    <div className="flex flex-row my-1">
      <div className="flex flex-col">
        <div className="flex flex-row bg-gray-200 rounded-tl rounded-tr shadow-lg justify-around items-end h-16 my-4">
          {
            // todo normalize the height.
            // take max / max height of container

            Object.keys(data)
              .map((k) => parseFloat(k))
              .sort((a, b) => a - b)
              // .map((k) => k.toFixed(1))
              .map((k, index) => {
                const v = data[k] || 0

                return (
                  <div onTouchStart={() => setCurr(k.toFixed(1))} onMouseEnter={() => setCurr(k.toFixed(1))} className="hover:bg-gray-300 hover:cursor-pointer flex flex-row items-end h-full w-4">
                    <div style={{
                      'height': `${((v * scale) + offs)}px`,
                    }} className="hover:cursor-pointer w-4 hover:bg-blue-800 bg-blue-600">
                    </div>
                  </div>
                )
              })
          }
        </div>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col items-end">
            <span className="flex flex-row items-center gap-1 text-xs font-bold">0 <FaStar /></span>
          </div>
          {curr && <span className="flex flex-row items-center font-bold">{curr}<FaStar /> <span className="flex flex-row mb-3 text-xs">({data[parseFloat(curr)]})</span>

          </span>}
          <div className="flex flex-col items-start">
            <span className="flex flex-row items-center gap-1 text-xs font-bold">{max} <FaStar /></span>
          </div>
        </div>
      </div>
    </div>
  )
}

const StatsContainer = ({ userId, stats }: any) => {
  const [dist, setDist] = useState<any>({})

  useEffect(() => {
    if (userId) {
      const isStar = localStorage.getItem('_ratingSystem') == 'STAR'
      Promise.resolve(Backend.getRatingDistribution(userId, isStar ? 'STAR' : 'NUMERIC'))
        .then((obj) => {
          if (obj) {
            setDist(obj)
          }
        })
    }
  }, [])

  const hasDist = true

  if (!stats) {
    return (
      <></>
    )
  }

  return (
    <div className="mx-4 my-2">
      <h3 className="font-bold">Stats</h3>

      <div className="flex flex-col lg:flex-row gap-2 justify-between">

        {hasDist && <div className="flex flex-col items-center w-full">
          <RatingHistogram data={dist} />
        </div>
        }

        <div className="py-2 w-full">
          <ul>
            <li className="flex flex-row justify-between">
              <span className="font-bold">Reviews</span>
              <span>{stats.reviewDetail.Total} / {stats.reviewDetail.AggregateBy["YEAR"]} this year</span>
            </li>
            <li className="flex flex-row justify-between">
              <span className="font-bold">Ratings</span>
              <span>{stats.ratingDetail.Total} / {stats.ratingDetail.AggregateBy["YEAR"]} this year</span>
            </li>
            <li className="flex flex-row justify-between">
              <span className="font-bold">Streak</span>
              <span>{stats.longestStreak} days</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const ListLink = ({ external_id, title, description, is_pinned, albums = [] }) => {
  const navigate = useNavigate()
  const [listAlbums, setAlbums] = useState(albums || [])

  const onClick = () => {
    navigate(`/view/list/${external_id}`)
  }

  return (
    <div onClick={onClick} className="flex flex-row bg-neutral items-center p-1 rounded-lg w-full hover:cursor-pointer">
      <div className="flex flex-col w-2/4 p-2">
        <h3 className="font-bold">{title} {
          (is_pinned && <span data-tip={`Pinned`} className='tooltip tooltip-bottom tooltip-xs text-blue-500 mx-1'>
            <div className='text-lg rotate-45'><FaThumbtack /></div>
          </span>)
        }</h3>
        <p className="text-xs">{sliceContent(description || 'No description given', 48)}</p>
      </div>

      <div className="w-2/4 flex flex-row justify-end">
        <div className="flex flex-row w-full list-item-cards rounded-lg">
          {
            listAlbums.length == 0 && <span className="text-xs">This list is empty!</span>
          }
          {
            listAlbums
              .slice(0, Math.min(6, listAlbums.length))
              .map(({ albms_id }: any, index) =>
                <ListItemThumbnail key={`llt-${index}-${albms_id}`} index={index} albms_id={albms_id} />)
          }
        </div>
      </div>
    </div>
  )
}

const ListsForProfileContainer = ({ username }: any) => {
  const [lists, setLists] = useState<any>([])

  useEffect(() => {
    if (username) {
      Backend.getListsForUser(username)
        .then((obj: any) => {
          setLists(obj)
        })
    }
  }, [username])

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row justify-between items-center mx-4">
        <h2 className="text-xl font-bold">Lists</h2>
      </div>

      {
        (lists || []).length == 0 && (
          <div className="mx-4">
            <p className="mb-6">Nothing to see here.</p>
          </div>
        )
      }

      <div className="flex flex-col gap-3 my-2 mx-4">
        {
          (lists || [])
            .sort((a, b) => a.is_pinned ? -1 : 1)
            .map((list: any, index: number) => (
              <ListLink key={`ll-${index}-${list.external_id}`} {...list} />
            ))
        }
      </div>
    </div>
  )
}

const RatingContainer = ({ results, username }: any) => {
  const navigate = useNavigate()

  return (
    <div>
      <div className="flex flex-row justify-between items-center mx-4">
        <h2 className="text-xl font-bold">Ratings</h2>
        {(results || []).length != 0 && <button onClick={() => navigate(`/ratings/${username}`)} className="btn btn-xs">
          VIEW ALL <FaArrowRight />
        </button>}
      </div>
      {
        results.length == 0 && (
          <div className="mx-4">
            <p className="mb-6">Nothing to see here.</p>
          </div>
        )
      }

      <ResultsContainer>
        {
          results &&
          results
            .slice(0, Math.min(results.length, 6))
            .map((result: any, index: any) =>
              <FeedItem
                key={`ratings-${index}`}
                small
                showMiniRating
                showAlbumDetail
                clickable={`/view/album/${result.albms_id}`}
                {...result} />)
        }
      </ResultsContainer>
    </div>
  )
}

const RecentsContainer = ({ results, username }: any) => {
  const navigate = useNavigate()

  return (
    <div>
      <div className="flex flex-row justify-between items-center mx-4">
        <h2 className="text-xl font-bold">Review Log</h2>
        {(results || []).length != 0 && username && <button onClick={() => navigate(`/feed/${username}`)} className="btn btn-xs">
          VIEW ALL <FaArrowRight />
        </button>}
      </div>

      {
        results.length == 0 && (
          <div className="mx-4">
            <p className="mb-6">Nothing to see here.</p>
          </div>
        )
      }

      <ResultsContainer>
        {
          results &&
          results
            .slice(0, Math.min(results.length, 6))
            .map((result: any, index: any) =>
              <FeedItem
                key={`recent-${index}`}
                small
                showMiniRating
                showAlbumDetail
                {...result} />)
        }
      </ResultsContainer>
    </div>
  )
}

const PinnedContainer = ({ results }: any) => {
  const [pinned, setPinned] = useState<any>([])

  useEffect(() => {
    setPinned([...results.filter((r: any) => r.is_pinned)])
  }, [results])

  return (
    <div>
      <h2 className="mx-4 text-xl font-bold">Pinned</h2>
      {
        pinned.length == 0 && (
          <div className="mx-4">
            <p className="mb-6">Nothing to see here.</p>
          </div>
        )
      }

      {(pinned && pinned.length != 0) &&
        <div className="px-2 my-2 flex flex-col justify-evenly">
          {
            pinned
              .map((result: any, index: any) =>
                <FeedItem
                  key={`pinned-${index}`}
                  pinned
                  {...result} />)
          }
        </div>
      }
    </div>
  )
}

const ProfileListPage = ({ data }) => {
  return (
    <div>
      {
        data &&
        data
          .sort((a: any, b: any) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()))
          .map(({ username, tier, image_url }) => (
            <div>
              <PopulatedProfileBadge tier={tier} imageUrl={image_url} username={username} />
            </div>
          ))
      }
    </div>
  )
}

const linkToHumanForm = (t) => {
  try {
    const url = new URL(t);
    return url.hostname;
  } catch (error) {
    console.error("Invalid URL:", t);
    return null;
  }
}

const BannerUpload = ({ src, ar = (1 / 1) }) => {
  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })

  const onImageLoad = (e: any) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: '%',
          width: 90,
        },
        ar,
        width,
        height
      ),
      width,
      height
    )

    setCrop(crop)

  }

  return (
    <ReactCrop crop={crop} onChange={c => setCrop(c)} aspect={ar}>
      <img src={src} onLoad={onImageLoad} />
    </ReactCrop>
  )
}

const BannerPicModal = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false)

  const saveProfilePic = () => {
    setLoading(true)

    const formData = new FormData();
    formData.append('file', selectedImage);

    Backend.uploadBannerPic(formData)
      .then((resp) => {
        setLoading(false)
        if (resp.ok) {
          if (confirm('Uploaded ok! It might take a while for your changes to be reflected across the site')) {
            // hide modal
          }
        } else {
          alert('Upload failed. Try again later')
        }
      })
  }

  return (
    <dialog id="editBannerPicModal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>

        <h3 className="font-bold text-lg">Upload a banner picture</h3>
        <div className="mb-2">
          <p className="text-sm">
            Images must be PNG, JPEG, or JPG.
          </p>
        </div>

        <div>
          <input
            type="file"
            className="file-input w-full max-w-xs"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setSelectedImage(event.target.files[0]);
            }}
          />
        </div>

        <form method="dialog">
          <div className="my-4">
            <div className="flex flex-col items-center mb-2">
              {loading ? <><Loading /></> : <button
                className="btn btn-sm btn-wide btn-success"
                onClick={saveProfilePic}>
                Upload
              </button>}
            </div>
          </div>
        </form>
      </div>
    </dialog>
  )
}

const ProfilePicModal = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false)

  const saveProfilePic = () => {
    setLoading(true)

    const formData = new FormData();
    formData.append('file', selectedImage);

    Backend.uploadProfilePic(formData)
      .then((resp) => {
        setLoading(false)
        if (resp.ok) {
          if (confirm('Uploaded ok! It might take a while for your changes to be reflected across the site')) {
            // hide modal
          }
        } else {
          alert('Upload failed. Try again later')
        }
      })
  }

  return (
    <dialog id="editProfilePicModal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>

        <h3 className="font-bold text-lg">Upload a profile picture</h3>
        <div>
          <input
            type="file"
            className="file-input w-full max-w-xs"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setSelectedImage(event.target.files[0]);
            }}
          />
        </div>

        <form method="dialog">
          <div className="my-4">
            <div className="flex flex-col items-center mb-2">
              {loading ? <><Loading /></> : <button
                className="btn btn-sm btn-wide btn-success"
                onClick={saveProfilePic}>
                Upload
              </button>}
            </div>
          </div>
        </form>
      </div>
    </dialog>
  )
}

const WithProfileCheck = ({ userId, children }: any) => {
  const [exists, setExists] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (userId) {
      Backend.doesUserExist(userId)
        .then((exists) => {
          if (exists) {
            setExists(true)
          } else {
            navigate(`/404?ref=${window.location.href}`)
          }
        })
    }
  }, [userId])

  return (
    <div>
      {exists && children}
    </div>
  )
}

const ProfilePage = ({ userId }: any) => {
  const [profile, setProfile] = useState<any>(undefined)
  const [results, setResults] = useState<any>(undefined)
  const [ratings, setRatings] = useState<any>(undefined)
  const [isMyProfile, setIsMyProfile] = useState(false)
  const [stats, setStats] = useState<any>(0)

  const [newBioContent, setNewBioContent] = useState(undefined)
  const [newLinkContent, setLinkContent] = useState(undefined)
  const [newLocationContent, setLocationContent] = useState(undefined)

  const session = useSessionContext() as any

  useEffect(() => {
    Backend.myStats()
      .then((obj) => setProfile(obj))
  }, [userId])

  const editBioClicked = () => {
    // if we have a bio, load it into the edit bio modal
    if (stats && stats.bio) {
      setNewBioContent(stats.bio)
      setLinkContent(stats.link)
      setLocationContent(stats.location)
    }

    (document.getElementById('editMyBioModal') as any).showModal()
  }

  const customProfileClicked = () => {
    if (!isMyProfile) {
      return
    }

    (document.getElementById('editProfilePicModal') as any).showModal()
  }

  const customBannerClicked = () => {
    if (stats?.tier === 'BASIC') {
      alert('Please consider upgrading to a PRO or PATRON membership to upload a custom banner!')
      return
    }
    if (!isMyProfile) {
      return
    }

    (document.getElementById('editBannerPicModal') as any).showModal()
  }

  const onSave = () => {
    Backend.updateStats({
      bio: newBioContent,
      link: newLinkContent,
      location: newLocationContent,
    })
      .then((obj) => {
        setTimeout(() => {
          reloadStats()
        }, 100)
      })
  }

  useEffect(() => {
    if (userId) {
      document.title = `albms.net - ${userId}'s profile`

      reloadStats()

      Backend.getFeed(userId)
        .then((response) => response.json())
        .then((obj) => {
          setResults((obj || []).filter((r: any) => !r.is_archived))
        })

      Backend.getFeedRatings(userId)
        .then((response) => response.json())
        .then((obj) => {
          setRatings(obj)
        })
    }

    if (userId && profile) {
      if (userId === profile.username) {
        setIsMyProfile(true)
      }
    }
  }, [userId, profile])

  const reloadStats = () => {
    Backend.statsWithDetail(userId)
      .then((resp) => {
        return resp.json()
      })
      .then((obj) => {
        setStats(obj)
        setNewBioContent(obj.bio)
      })
  }

  return (
    <WithProfileCheck userId={userId}>
      <div className="w-full">

        <div className="flex flex-col">
          <div className="flex flex-col mb-14 w-full">
            <div onClick={customBannerClicked} className="z-150 flex mx-4 flex-row bg-center bg-cover h-40 bg-blue-500 rounded-lg shadow-sm" style={{
              backgroundImage: `url("${stats?.bannerImageUrl}")`
            }}>
            </div>
            <div>
              <div className={`flex flex-row justify-end mx-4 mt-4 -my-10`}>
                <div className="flex flex-row gap-2 items-center">
                  {
                    (session.doesSessionExist && isMyProfile) && (
                      <>
                        <div className="flex flex-row gap-1">
                          <button onClick={editBioClicked} className="btn btn-xs btn-primary">
                            Edit Profile
                          </button>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            </div>

            <div>
              <div onClick={customProfileClicked} className={`z-100 flex flex-row w-24 h-24 md:w-32 md:h-32 shadow-lg rounded-full -my-14 md:-my-20 md:mx-10 mx-8 ${isMyProfile && 'hover:cursor-pointer'}`}>
                <img
                  loading="lazy"
                  className="z-200 rounded-full w-full bg-white object-cover shadow-lg" src={stats?.imageUrl || ''} />
              </div>
            </div>
          </div>

          <div className="flex flex-col mx-4 overflow-x-hidden">
            <div>
              <div className="flex flex-row items-center justify-between mb-2">
                <div className="items-center">
                  <ProfileBadge noLink noImage username={userId} />
                </div>

                <div className="flex flex-row items-center gap-1">
                  <div data-tip='Daily streak' className="tooltip tooltip-bottom tooltip-xs">
                    <div className="badge badge-ghost badge-xs p-2 font-bold text-xs">
                      <FaFire /> &nbsp; {stats.streak || 0}
                    </div>
                  </div>

                  <div data-tip='Total ratings' className="tooltip tooltip-bottom tooltip-xs">
                    <div className="badge badge-ghost badge-xs p-2 font-bold text-xs">
                      <FaStar /> &nbsp; {stats?.ratingDetail?.Total || 0}
                    </div>
                  </div>

                  <div data-tip='Total reviews' className="tooltip tooltip-left tooltip-xs">
                    <div className="badge badge-ghost badge-xs p-2 font-bold text-xs">
                      <FaPencilAlt /> &nbsp; {stats?.reviewDetail?.Total || 0}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                {stats.bio && <p>{stats.bio}</p>}
              </div>
            </div>

            <div className="flex flex-col text-xs gap-1">
              <div className="flex flex-row gap-2">
                <span className="flex flex-row items-center gap-1"><FaLocationArrow /> {stats.location ? stats.location : 'Earth'}</span>
                {stats.link ? <span className="flex flex-row items-center gap-1"><FaLink /> <LinkTo target='_blank' to={stats.link} value={linkToHumanForm(stats.link)} /></span> : <span></span>}
              </div>
              <div className="flex flex-row">
                <span className="flex flex-row items-center gap-1"><FaCalendarAlt /> Joined {stats.joined_at && moment(stats.joined_at).format('Do MMM, YYYY')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4" />

      <div className="flex flex-row w-full justify-around">
        {
          ratings && (
            ((results || []).length + ((ratings?.results) || []).length) != 0 ? (
              <div className="flex flex-col w-full">
                <RecentsContainer username={userId} results={(results || [])} />
                <RatingContainer results={(ratings.results || [])} username={userId} />
                <PinnedContainer results={results || []} />
              </div>
            ) : (
              <div>

              </div>
            )
          )
        }
      </div>

      <StatsContainer userId={userId} stats={stats} />

      <div className="mb-8" />

      <BannerPicModal />
      <ProfilePicModal />

      <dialog id="editMyBioModal" className="modal">
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>

          <h3 className="font-bold text-lg">Update your profile</h3>

          <form method="dialog">
            <div className="my-4">
              <p className="text-sm font-bold">Change your bio</p>
              <input
                type="text"
                placeholder="I like birds, bees, I like people."
                className="input input-bordered input-primary w-full"
                value={newBioContent}
                onChange={(e) => setNewBioContent(e.target.value)}
              />
            </div>

            <div className="my-4">
              <p className="text-sm font-bold">Set your location</p>
              <input
                type="text"
                placeholder="Earth"
                className="input input-bordered input-primary w-full"
                value={newLocationContent}
                onChange={(e) => setLocationContent(e.target.value)}
              />
            </div>

            <div className="my-4">
              <p className="text-sm font-bold">Link something</p>
              <input
                type="url"
                placeholder="https://google.com"
                className="input input-bordered input-primary w-full"
                value={newLinkContent}
                onChange={(e) => setLinkContent(e.target.value)}
              />
            </div>

            <div>
              <p className="text-sm font-bold">Change your profile picture</p>
              <p className="text-xs">
                To change your profile picture simply tap or click on your profile
                picture in the view profile screen.
              </p>
            </div>

            <div className="my-4">
              <p className="text-sm font-bold">Upload a profile banner</p>
              {stats?.tier === 'BASIC' && <div className="alert alert-warning my-1">
                <p className="text-left text-xs">
                  This feature is available to <ProBadge /> and <PatronBadge /> members. Please
                  consider becoming a member to support the site and have more features like banner
                  setting! <LinkTo value={'Upgrade today'} to='/pro' />.
                </p>
              </div>}
              {stats?.tier !== 'BASIC' && <div className="my-1">
                <button onClick={customBannerClicked} className="btn btn-sm btn-success">
                  Upload Custom Banner
                </button>
              </div>}
            </div>

            <div className="flex flex-row justify-between">
              <button className="btn btn-sm btn-primary">
                Cancel
              </button>

              <button onClick={onSave} className="btn btn-success btn-sm btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </WithProfileCheck>
  )
}

export {
  ProfilePage
}