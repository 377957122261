import { Rating } from "react-simple-star-rating"
import { ImageWithErr } from "./image"
import { Backend } from "../client/backend"
import { RatingContainer } from "./rating"
import { useNavigate } from "react-router-dom"

const ThumbnailFeedItem = ({ index = undefined, album_title, albms_id, artist_name, rating, showMiniRating = false, clickable = `/view/album/${albms_id}`, showAlbumCover = true, children = undefined, showAlbumDetail = true }) => {
  const navigate = useNavigate()

  const isStar = localStorage.getItem('_ratingSystem') === 'STAR'

  const onClick = () => {
    if (clickable) {
      navigate(clickable)
    }
  }

  return (
    <div className={`px-1 sm:px-2 flex flex-row`}>
      {
        showAlbumCover && (
          <div className={`flex flex-col justify-start`}>
            <div onClick={onClick} className={`${clickable && 'hover:cursor-pointer'} self-center w-20 md:w-32`}>
              <ImageWithErr loading={index < 6 ? 'eager' : 'lazy'} classNames="mt-2" src={Backend.imageFor(albms_id)} alt={`cover art for ${artist_name} - ${album_title}`} />

              <span className="flex flex-row justify-around w-full">
                {showMiniRating &&
                  <>
                    {
                      isStar ? (
                        <Rating
                          size={16}
                          SVGclassName={`inline-block`}
                          readonly
                          allowFraction
                          initialValue={rating / 2} />
                      ) : (
                        <>
                          {(showMiniRating) && <span style={{
                            marginTop: '-16px'
                          }}>
                            <RatingContainer size='small' rating={rating} />
                          </span>}
                        </>
                      )
                    }
                  </>
                }
              </span>

              {(showAlbumDetail) && (
                <div className="flex flex-col w-3/4 text-xs overflow-x-hidden my-1">
                  <span>{album_title}</span>
                  <span className="font-bold text-[8px]">{artist_name}</span>
                </div>
              )}
            </div>

          </div>
        )
      }

      {children && children}
    </div>
  )
}

export {
  ThumbnailFeedItem
}