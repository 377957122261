import { FaDice } from "react-icons/fa"
import { IoDocumentTextSharp, IoList, IoSearch } from "react-icons/io5"
import { useNavigate } from "react-router-dom"


const SubmitDecision = ({ children = undefined }) => {
  const navigate = useNavigate()

  return (
    <div>
      <div className="flex flex-col items-center my-4">
        <div className="flex flex-col gap-2 items-center w-full">
          <button
            className="btn btn-block btn-success"
            onClick={() => navigate('/submit')}>
            <IoDocumentTextSharp /> Submit Rating / Review
          </button>
          <button
            className="btn btn-block"
            onClick={() => navigate('/search')}>
            <IoSearch />Search for an Album
          </button>
        </div>
      </div>

      <div className="divider" />

      <div className="w-full flex flex-col items-center gap-2 py-2">
        {children}

        <button onClick={() => navigate('/shuffle')} className="btn btn-block btn-primary">
          <>I'm feeling lucky <span className="text-2xl"><FaDice /></span></>
        </button>
      </div>
    </div>
  )
}

export {
  SubmitDecision
}