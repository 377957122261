import { useEffect, useState } from "react"
import { Backend } from "../client/backend"

const Histogram = ({ data, sortFn = undefined }) => {
  const [curr, setCurr] = useState<any>(undefined)

  const isStar = localStorage.getItem('_ratingSystem') == 'STAR'

  const offs = 0
  const containerHeight = 64 // how can we scale this...
  const maxVal: any = data ? (Object.values(data) || []).sort().reverse()[0] : 1
  const maxHeight = Math.max(maxVal, containerHeight)

  // scale to fit NEAR max height of the container
  const scale = (containerHeight / maxHeight) * (isStar ? 1.25 : 2.5)

  return (
    <div className="flex flex-col my-1">
      <div className="flex flex-col">
        <div className="flex flex-row bg-gray-200 rounded-tl rounded-tr shadow-lg justify-around items-end h-16 my-4">
          {
            // todo normalize the height.
            // take max / max height of container

            Object.keys(data)
              .sort(sortFn)
              .map((k) => {
                const v = data[k] || 0

                return (
                  <div onTouchStart={() => setCurr(k)} onMouseEnter={() => setCurr(k)} className="hover:bg-gray-300 hover:cursor-pointer flex flex-row items-end h-full w-16">
                    <div style={{
                      'height': `${((v * scale) + offs)}px`,
                    }} className="hover:cursor-pointer w-16 hover:bg-blue-800 bg-blue-600"></div>
                  </div>
                )
              })
          }
        </div>
      </div>
      <div className="w-full flex flex-row justify-between">
        {curr && (
          <>
            <div>
              {curr} <span className="text-sm text-gray-500">({data[curr]})</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const Stat = ({ name, val }) => {
  return (
    <div className="stat">
      <div className="stat-value font-bold">
        {val}
      </div>
      <div className="stat-title text-lg capitalize">
        {name.replaceAll('_', ' ')}
      </div>
    </div>
  )
}

const isVisibleStat = (st) => [
  'reviews', 'ratings', 'users', 'lists', 'list_items',
  'searches', 'advanced_searches', 'members', 'weekly_signups'
].includes(st)

const StatsPage = () => {
  const [stats, setStats] = useState({})

  const load = () => {
    Backend.getDashStats()
      .then((obj) => {
        if (obj && obj.reviews) {
          setStats(obj)
        }
      })
  }

  useEffect(() => {
    load()
    setInterval(() => {
      load()
    }, 5000)
  }, [])

  return (
    <>
      <div className="grid grid-cols-2">
        {
          (Object.entries(stats))
            .filter(([key, _]) => isVisibleStat(key))
            .sort((a: any, b: any) => b[1] - a[1])
            .map(([key, val]: any) => {
              return (
                <Stat name={key} val={val} />
              )
            })
        }
      </div>
    </>
  )
}

export {
  StatsPage
}