import React, { useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";

const ReviewConfirmation = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'albms.net - review submitted'
  }, [])

  return (
    <>

      <div className="m-4">
        <div className="flex flex-row justify-around">
          <h2 className="text-4xl font-bold my-8">Review Submitted 🎉</h2>
        </div>

        <div className="flex flex-row justify-around w-full">
          <ConfettiExplosion
            force={0.8}
            particleCount={300} />
        </div>

        <div>
          <div className="flex flex-col text-center my-2">
            <p className="text-lg">
              Nice work! How about entering another review for an album?
            </p>
            <div className="my-2 text-sm">
              <p className="text-sm">
                Help us shape the future of albms.net &mdash; contribute your feedback by <a className='hover:cursor-pointer text-blue-500 font-bold hover:underline' href='https://forms.gle/jU5BEFyj4KvG3UKP9' target='_blank'>submitting this survey</a>.
              </p>
            </div>
          </div>

          <div>
            <div className="flex flex-row justify-around">
              <div className="flex flex-row gap-2 my-4">
                <div className="flex flex-row justify-around">
                  <button onClick={() => navigate('/')} className="btn">
                    Home
                  </button>
                </div>
                <div className="flex flex-row justify-around">
                  <button onClick={() => navigate('/submit')} className="btn btn-success">
                    Submit Log / Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  )
}

export {
  ReviewConfirmation
}