import { ThumbnailFeedItem } from "./thumbnail_feed_item"

const RatingFeedItemContainer = ({ showMiniRating = false, results, small = false, showDetails = false }: any) => {
  return (
    <>
      {
        results &&
        results.map((result: any, index: any) =>
          <ThumbnailFeedItem
            index={index}
            showMiniRating={showMiniRating}
            small={small}
            showAlbumDetail={showDetails}
            clickable={`/view/album/${result.albms_id}`}
            key={`feed-item-${index}-${result.albms_id}`} {...result} />)
      }
    </>
  )
}



export {
  RatingFeedItemContainer
}