import { useEffect, useState } from "react"
import { ArbitraryAlbumHorizontalFeed, RatingFeed } from "./rating_feed"
import { PatronBadge } from "../component/profile_badge"
import { Backend } from "../client/backend"
import { LinkTo } from "./list"
import { useSessionContext } from "supertokens-auth-react/recipe/session"
import { useNavigate } from "react-router-dom"
import { FaExternalLinkAlt } from "react-icons/fa"
import { DiscoverPage } from "./discover"

const HeroBanner = () => {
  return (
    <>
      <div className="flex flex-row justify-around my-2">
        <h1 className="text-4xl font-bold"><span className="hover:animate-spin">💿</span> albms.net</h1>
      </div>
      <div className="flex flex-col my-4 leading-relaxed items-center lg:text-2xl text-lg">
        <p>Track music you've listened to.</p>
        <p>Find new music to listen to later.</p>
        <p>Share with friends what's good.</p>
      </div>
    </>
  )
}

const SalesBanner = () => {
  return (
    <>
      <div className="flex flex-row my-4 gap-4">
        <div className="flex flex-row w-2/6 card-body rounded-xl">
          <div className="flex flex-col ">
            <h4 className="text-2xl font-bold">Discovery</h4>
            <p>
              we recommend new music to you based on yours and others activity.
            </p>
          </div>
        </div>

        <div className="flex flex-row w-4/6 card-body rounded-xl">
          <div className="flex flex-col ">
            <h4 className="text-2xl font-bold">Activity Log</h4>
            <p>
              keep track of what you listen to with the albms.net activity log.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-row my-4 gap-4">
        <div className="flex flex-row w-5/8 card-body rounded-xl">
          <div className="flex flex-col ">
            <h4 className="text-2xl font-bold">Share with friends</h4>
            <p>
              invite and follow friends. you can see what they are reviewing in your home feed.
            </p>
          </div>
        </div>

        <div className="flex flex-row w-3/8 card-body rounded-xl">
          <div className="flex flex-col ">
            <h4 className="text-2xl font-bold">Free for now!</h4>
            <p>
              albms.net is a free product. we're still trying to work things out.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

type States = 'friends' | 'everyone'

const HighlightedReviews = () => {
  const [state, setState] = useState<States>('everyone')

  const inviteFriend = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Follow me on albms.net',
        url: `https://albms.net/`,
        text: "I'm using albms.net to rate, log, and track my music"
      }).then(() => { })
        .catch(console.error);
    }
  }

  const flipState = () => {
    setState((prev) => (
      prev === 'friends' ? 'everyone' : 'friends'
    ))
  }

  return (
    <div className="mx-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <h2 className="text-xl font-bold my-2">
            From the community
          </h2>
          <p className="text-sm">Some highlighted reviews from other albms.net reviewers</p>
        </div>
      </div>
      <>
        <DiscoverPage />
      </>
    </div>
  )
}

const LoggedOutView = () => {
  const navigate = useNavigate()
  const session = useSessionContext() as any

  useEffect(() => {
    document.title = 'albms.net - home'
  }, [])

  return (
    <>
      <div className="m-4">
        <div className="card">
          <div id='backdropMask' className="bg-gradient-to-r from-purple-400 to-blue-500 card-body text-white font-bold rounded-xl bg-fit bg-center shadow-xl">
            <HeroBanner />

            {
              session.doesSessionExist ? (
                <div className="flex flex-col items-center">
                  <div className="flex flex-col gap-2 items-center">
                    <div className="card-actions justify-around">
                      <div className="indicator">
                        <button onClick={() => {
                          window.open('https://forms.gle/jU5BEFyj4KvG3UKP9', '_blank')
                        }} className="btn btn-success shadow-xl">FEEDBACK SURVEY <span className="text-gray-800 text-xs"><FaExternalLinkAlt /></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <div className="flex flex-col gap-2 items-center">
                    <div className="card-actions justify-around">
                      <div className="indicator">
                        <button onClick={() => navigate('/auth')} className="btn btn-success shadow-xl">GET STARTED &mdash; IT'S FREE!</button>
                      </div>
                    </div>
                    <div className="card-actions justify-around">
                      <button onClick={() => navigate('/auth')} className="btn btn-ghost btn-sm">Sign In</button>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>

        {/* <SalesBanner /> */}
      </div>

      <div className="mx-4">
        <div className="flex flex-row justify-between">
          <h2 className="text-xl font-bold my-2">Recently rated</h2>
        </div>

        <>
          <RatingFeed />
        </>
      </div>

      <div className="mx-4">
        <div className="flex flex-row justify-between">
          <h2 className="text-xl font-bold my-2">Popular this month</h2>
        </div>

        <ArbitraryAlbumHorizontalFeed />
      </div>

      <HighlightedReviews />
    </>
  )
}

const SubBanner = () => {
  return (
    <div className="mx-4 mb-4">
      <div role="alert" className="alert alert-success rounded-xl shadow-lg">
        <div className="hidden md:flex">
          <PatronBadge />
        </div>
        <div>
          <div className="text-sm leading-relaxed">
            <p>
              Support the development of albms.net by upgrading to a <LinkTo to='/pro' value='pro or patron tier' /> today.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const Home = () => {
  const [tier, setTier] = useState<any>('BASIC')

  useEffect(() => {
    Backend.myStats()
      .then((obj) => {
        if (obj && obj.tier) {
          setTier(obj.tier)
        }
      })
  }, [])

  return (
    <>
      <LoggedOutView />
    </>
  )
}

export {
  Home
}
