const Loading = ({ minimal = false, size = undefined }) => {
  const sizeStyles = (size: any) => {
    return {
      'small': 'text-xl',
      'xs': 'text-xs',
    }[size] || 'text-4xl'
  }

  return (
    <div className='flex flex-col items-center gap-2'>
      <span className={`${sizeStyles(size)}`}>
        <div className='animate-spin'>💿</div>
      </span>
    </div>
  )
}

export {
  Loading
}