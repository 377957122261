import { useEffect, useRef, useState } from "react"
import { Backend } from "../client/backend"
import { Loading } from "../util/loading"
import { useNavigate, useParams } from "react-router-dom"
import { NothingLeftContainer } from "../component/run_out_of_stuff"
import moment from "moment"
import { FaAlignJustify, FaCalendarDay, FaEllipsisH, FaHeart } from "react-icons/fa"
import { IoRepeat } from "react-icons/io5"
import { LuAlignJustify } from 'react-icons/lu'
import { ImageWithErr } from "../component/image"
import { ResponsiveRatingContainer } from "../component/rating"
import { BigProfileBadge } from "../component/profile_badge"

const DefaultPredicate = (items: Array<any>) => {
  return items.filter((i) => !i.is_archived)
}

const ActivityFeedItem = ({
  isFirstOfMonth = false,
  album_title,
  artist_name,
  albms_id,
  external_id,
  rating,
  is_pinned,
  is_relisten,
  is_archived,
  is_favourite,
  content_sample,
  created_at,
  listened_at
}) => {
  const navigate = useNavigate()

  const onClick = () => {
    // TODO if rating show user-rating entry?
    // maybe we should 
    navigate(`/review/${external_id}`)
  }

  return (
    <div className="flex flex-row">
      <div className="flex flex-row mt-2 w-4 md:w-8 mx-4">
        {isFirstOfMonth && <div className="flex flex-col font-bold">
          <span className="flex flex-row gap-1 items-center text-xs">
            <span className="text-xs text-gray-700"><FaCalendarDay /></span>
            {moment(listened_at).format('MMM').toUpperCase()}
          </span>
          <span className="text-sm self-end">{moment(listened_at).format('YYYY')}</span>
        </div>}
      </div>

      <div className="flex flex-row font-bold text-xs justify-end items-start mt-2 mx-4">
        {moment(listened_at).format('DD')}
      </div>

      <div className="w-12 mt-1 md:w-16 mr-2 md:mr-4">
        <span className="hover:cursor-pointer" onClick={() => navigate(`/view/album/${albms_id}`)}>
          <ImageWithErr src={Backend.imageFor(albms_id, 'md')} />
        </span>

        <div className="flex flex-row md:hidden text-xs my-1 justify-around">
          <div className="flex flex-row items-center">
            <span className="text-red-500">{is_favourite && <FaHeart />}</span>
          </div>

          <div className="flex flex-row items-center">
            {is_relisten && <IoRepeat />}
          </div>

          <div className="flex flex-row items-center">
            {content_sample && <LuAlignJustify />}
          </div>
        </div>
      </div>

      <div className="flex flex-col font-bold text-lg my-0 w-2/5">
        <span onClick={onClick} className="hover:cursor-pointer">{album_title}</span>

        <div className="flex flex-row gap-2 md:hidden">
          <ResponsiveRatingContainer rating={rating} />
        </div>
      </div>

      <div className="hidden md:flex flex-row justify-around items-center md:w-1/6">
        <ResponsiveRatingContainer rating={rating} />
      </div>

      <div className="hidden md:flex flex-row items-center w-8">
        <span className="text-red-500">{is_favourite && <FaHeart />}</span>
      </div>

      <div className="hidden md:flex flex-row items-center w-8">
        {is_relisten && <IoRepeat />}
      </div>

      <div className="hidden md:flex flex-row items-center w-8">
        {content_sample && <LuAlignJustify />}
      </div>

      <div className="flex flex-row items-center justify-end w-16 md:w-8 mr-2">
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role='button' className='btn-circle btn btn-xs'><FaEllipsisH /></div>
          <ul tabIndex={0} className='dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-32'>
            <li onClick={() => navigate(`/view/album/${albms_id}`)}><a>View Album</a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

const ActivityLog = ({ results }) => {
  let prevMonth = undefined

  return (
    <div className="flex flex-col gap-2">
      {
        results.slice().map((result, index, array) => {
          const curr = new Date(result.listened_at)

          const isFirstOfMonth = curr.getMonth() !== prevMonth
          prevMonth = curr.getMonth()

          return <ActivityFeedItem
            key={`feed-item-${index}-${result.external_id}`}
            isFirstOfMonth={isFirstOfMonth}
            {...result} />
        })
      }
    </div>
  )
}

const FeedPage = ({ predicate = DefaultPredicate }) => {
  const { username } = useParams()
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [lastRequestedPageIndex, setLastRequestedPageIndex] = useState<any>(null);
  const loaderRef = useRef(null)
  const observer = useRef<any>(null)
  const [hasMoreResults, setHasMoreResults] = useState(true)

  const loadMore = () => {
    if (loading || pageIndex === lastRequestedPageIndex) return;
    setLoading(true)
    setLastRequestedPageIndex(pageIndex);

    if (username) {
      Backend.getFeed(username, pageIndex, 'LISTENED_AT')
        .then((response) => response.json())
        .then((obj) => {
          if (obj.length === 0) {
            setHasMoreResults(false)
          } else {
            setResults((prev: any) => [...prev, ...(obj || []).filter((r: any) => !r.is_archived)])
            setPageIndex(prev => prev + 1)
          }
        })
        .finally(() => {
          setLoading(false)
          if (loaderRef.current && hasMoreResults) {
            observer.current.observe(loaderRef.current)
          }
        })
    }
  }

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMoreResults) {
        observer.current.unobserve(loaderRef.current)
        loadMore();
      }
    }, { threshold: 1.0 });

    if (loaderRef.current) {
      observer.current.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.current.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef, loading]); // Re-run the effect if `loading` state changes


  useEffect(() => {
    loadMore(); // Initial load
  }, []);

  return (
    <>
      <div className="mx-4">
        <div className="mb-2">
          <BigProfileBadge username={username} />
        </div>
      </div>

      <div className='flex flex-col justify-around'>
        <ActivityLog results={results} />
      </div>

      {
        !hasMoreResults && (
          <NothingLeftContainer />
        )
      }

      <div ref={loaderRef} className='flex flex-row w-full justify-around my-8 text-gray-600'>
        {loading && (
          <>
            <Loading />
          </>
        )}
      </div>
    </>
  )
}

export {
  FeedPage
}