import { useEffect, useState } from "react"
import { FaDice, FaPlus } from "react-icons/fa"
import { Loading } from "../util/loading"
import { Backend } from "../client/backend"
import { SignedIn, useAuth, useUser } from "@clerk/clerk-react"
import { ImageWithErr } from "./image"
import { RatingFeedItemContainer } from "./rating_feed_items"
import moment from "moment"
import { useLocation, useNavigate } from "react-router-dom"
import { SubmitDecision } from "../pages/submit_decision"
import Session, { SessionAuth, useSessionContext } from "supertokens-auth-react/recipe/session";



const Album = ({ title = false }) => {
  return (
    <span className={`${title && 'capitalize'}`}>alb<span className=''>u</span>m</span>
  )
}

const Alert = ({ children, thumbnail = undefined }) => {
  return (
    <div>
      <div role="alert" className="alert flex flex-col pt-0">
        <div>
          {thumbnail && thumbnail()}
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

const CountdownTimer = () => {
  const getNextMonday = () => {
    const today = moment().utc();
    const nextMonday = today.clone().add(1, 'weeks').startOf('isoWeek');
    return nextMonday;
  };

  const calculateTimeLeft = () => {
    const difference = +getNextMonday() - +moment().utc();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        // seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <>
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </>
  );
};

const Countdown = () => {
  return (
    <>
      <p className="text-xs">
        <CountdownTimer /> left til the next AOTW roll
      </p>
    </>
  )
}

const WeeklyAlbumAdvertBanner = ({ onContinuePressed }) => {
  return (
    <Alert>
      <div className="flex flex-col leading-relaxed">
        <div className="flex flex-row gap-4 justify-between items-center">
          <div>
            <h2 className=" font-bold text-left">Get your <Album /> for this week</h2>
          </div>

          <button onClick={onContinuePressed} className="btn btn-success btn-xs shadow-lg">
            GET STARTED 💿
          </button>
        </div>
      </div>
    </Alert>
  )
}


const SelectedAlbum = ({ albumSelection, summary = undefined }) => {
  const [selected, setSelected] = useState<any>()

  useEffect(() => {
    if (albumSelection) {
      setSelected((albumSelection || []).slice(0, 1)[0])
    }
  }, [albumSelection])

  if (selected) {
    return (
      <div>
        <div className='flex flex-col justify-around w-full overflow-y-auto'>
          <div className="flex flex-col my-2">
            <h2 className="text-lg leading-relaxed">
              <span className="font-bold text-2xl uppercase">{selected.title}</span> by {selected.artist}
            </h2>
          </div>

          <div className="mb-4 flex flex-col items-center">
            <RatingFeedItemContainer
              small
              showMiniRating={false}
              results={[selected]} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

const AlbumSelection = ({ reRollClicked, continueClicked, reRollCount, albumSelection }) => {
  const [selected, setSelected] = useState<any>()

  useEffect(() => {
    if (albumSelection) {
      setSelected((albumSelection || []).slice(0, 1)[0])
    }
  }, [albumSelection])

  if (selected) {
    return (
      <div>
        <div className='flex flex-col items-center justify-around w-full overflow-y-auto'>
          <div className="flex flex-col my-2">
            <h2 className="text-lg leading-relaxed">
              <span className="font-bold text-2xl">{selected.title}</span> by {selected.artist}
            </h2>
          </div>

          <RatingFeedItemContainer
            small
            showMiniRating
            results={[selected]} />
        </div>

        <div className="flex flex-col items-center my-4">
          <div className="flex flex-col gap-2 items-center w-1/2">
            <button onClick={() => continueClicked(selected)} className="btn btn-success btn-wide">
              <>Continue with this album</>
            </button>
            <button disabled={reRollCount == 0} onClick={reRollClicked} className="btn btn-sm btn-primary">
              <>Re-roll ({reRollCount}) <span className="text-xl"><FaDice /></span></>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

// TODO make this a common component.
const RecentlyInteractedAlbumsCarousel = ({ username }) => {
  const [data, setData] = useState<any>([])

  useEffect(() => {
    Backend.aotwSample(username).then((obj) => {
      setData(obj.results)
    })
  }, [])

  if (data) {
    return (
      <div>
        <p className="text-xs">
          The <Album /> that is selected will be based on this selection of music you have recently been
          interacting with
        </p>

        <div className="flex flex-row justify-around my-4">
          <div className='flex flex-row w-full overflow-y-auto'>
            <RatingFeedItemContainer
              small
              showMiniRating
              results={data} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

const AlbumOfTheWeekWizard = () => {
  const [loading, setLoading] = useState(false)
  const [albumSelection, setAlbumSelection] = useState<any>(undefined)
  const [reRollCount, setReRollCount] = useState(2)
  const [enrolled, setEnrolled] = useState(false)
  const session = useSessionContext() as any
  const [profile, setProfile] = useState<any>(undefined)

  useEffect(() => {
    Backend.myStats()
      .then((obj) => {
        setProfile(obj)
      })
  }, [])

  const onContinuePressed = () => {
    // @ts-ignore
    document.getElementById('aotwPickerModal').showModal()
  }

  const onAlbumSelectionContinueClicked = () => {
    // @ts-ignore
    document.getElementById('goModal').showModal()
  }

  const onDiceRollClicked = (username: string) => {
    setAlbumSelection(undefined)

    Backend.aotwShuffle(username)
      .then((obj) => {
        if (obj) {
          setAlbumSelection(obj.results)
        }
        setLoading(false)
      })
    setLoading(true)
  }

  const onAlbumSelected = (selection) => {
    // TODO:
    // 1. send the _correlation id_ of the selection to continue with
    // 2. mark in the table as that being our selected album
    // 3. load the selection on the home-page and overlay a banner.
    // 4. on submit of that album review redirect to a diff confirm page.
    Backend.aotwSelect(selection.albms_id)
      .then((obj) => {
        setEnrolled(true)
      })
  }

  const reRollClicked = (username: string) => {
    if (reRollCount <= 0) {
      return
    }

    setAlbumSelection(undefined)

    Backend.aotwShuffle(username)
      .then((obj) => {
        if (obj) {
          setAlbumSelection(obj.results)
        }
        setLoading(false)
      })
      .finally(() => {
        setReRollCount((prev) => prev - 1)
      })
    setLoading(true)
  }

  if (!session.doesSessionExist || profile === undefined) {
    return (
      <>
      </>
    )
  }

  return (
    <>
      <dialog id="aotwPickerModal" className="modal">
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>

          <h3 className="font-bold text-lg capitalize"><Album title /> of the week</h3>

          {
            enrolled ? (
              <>
                <SelectedAlbum albumSelection={albumSelection} />

                <div className="flex flex-col gap-1 items-center">
                  <div className="leading-relaxed">
                    <p>
                      <span className="font-bold">You have been enrolled into Album of the week!</span> We will show a countdown
                      and a reminder at the bottom of the screen.
                    </p>
                  </div>

                  <div>
                    <p>
                      Submit a <span className="font-bold">written review</span> of this album to mark this week as completed. Have fun!
                    </p>
                  </div>

                  <form method='dialog'>
                    <button onClick={onAlbumSelectionContinueClicked} className="btn my-4">
                      Continue
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <>
                <p className="py-4">
                  Get an <Album /> to listen to and review this week based on your most recent
                  ratings, reviews, and hints of your music taste.
                </p>

                {
                  albumSelection ? (
                    <AlbumSelection continueClicked={onAlbumSelected} reRollClicked={() => reRollClicked(profile?.username)} reRollCount={reRollCount} albumSelection={albumSelection} />
                  ) : (
                    <>
                      {
                        loading ? <div className="my-8"><Loading /></div> : <RecentlyInteractedAlbumsCarousel username={profile?.username} />
                      }

                      <div className="flex flex-row justify-around my-4">
                        {!loading && <button disabled={loading} onClick={() => onDiceRollClicked(profile?.username)} className="btn btn-wide btn-primary">
                          <>I'm feeling lucky <span className="text-2xl"><FaDice /></span></>
                        </button>}
                      </div>
                    </>
                  )
                }
              </>
            )
          }
        </div>
      </dialog>

      <div className="rounded-tl-xl rounded-tr-xl">
        <WeeklyAlbumAdvertBanner onContinuePressed={onContinuePressed} />
      </div>
    </>
  )
}

const SelectedAlbumOfTheWeekBanner = ({ albmID }) => {
  const [info, setInfo] = useState<any>()
  const navigate = useNavigate()

  useEffect(() => {
    if (albmID) {
      Backend.getAlbumInfo(albmID)
        .then((obj) => obj.json())
        .then((obj) => {
          setInfo(obj)
        })
    }
  }, [albmID])

  if (!info) {
    return <>
      <Loading />
    </>
  }

  return (
    <div className="w-full flex flex-row justify-around">
      <div className="flex flex-col px-4">
        <div>
          <h3 className="font-bold text-sm">Don't forget your album for this week</h3>
          <p>
            <Countdown />
          </p>
        </div>

        <div className="flex flex-row gap-1 my-3">
          <button onClick={() => navigate(`/view/album/${albmID}`)} className="flex flex-col items-center justify-around w-12">
            <div className="flex hover:cursor-pointer flex-row justify-around w-full">
              <ImageWithErr src={Backend.imageFor(albmID)} />
            </div>
          </button>

          <div className="flex flex-col">
            <div>
              <h2 className="font-bold text-sm">{info.title}</h2>
              <p className="text-xs">By {info.artist}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const MiscOverlays = () => {
  const session = useSessionContext() as any
  const location = useLocation()

  const goClicked = () => {
    (document.getElementById('goModal') as any).showModal()
  }

  return (
    <>
      {
        session.doesSessionExist && (location.pathname == '/' || location.pathname.startsWith('/@')) && (
          <div className="bottom-0 right-0 fixed md:right-1/4 md:-mr-8 mr-4 mb-4">
            <button onClick={goClicked} className="btn btn-circle btn-lg bg-blue-500 text-white text-2xl shadow-lg">
              <span><FaPlus /></span>
            </button>
          </div>
        )
      }

      <dialog id="goModal" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
          </form>

          <h3 className="font-bold text-lg">What do you want to do?</h3>

          <form method="dialog">
            <SubmitDecision />
          </form>
        </div>
      </dialog>
    </>
  )
}

export {
  MiscOverlays,
  AlbumOfTheWeekWizard,
  Album,
  SelectedAlbum,
  AlbumSelection,
  RecentlyInteractedAlbumsCarousel
}