import { useAuth } from "@clerk/clerk-react"
import { useEffect, useState } from "react"
import { Backend } from "../client/backend"
import Session from "supertokens-auth-react/recipe/session";


const ReferralPage = () => {
  const [codes, setCodes] = useState<any>(['some-code', 'another-code'])

  useEffect(() => {
    Backend.getAvailableReferralCodes()
      .then((obj) => {
        if (obj && obj.codes) {
          setCodes(obj.codes)
        }
      })
  }, [])

  const shareCode = (code: string) => {
    // if navigator.share possible -> do that
    // else {
    // copy to clipboard.
    // }
  }

  return (
    <div className="mx-4">
      <h2 className="text-2xl font-bold">Refer a friend</h2>
      <p>
        Share a referral link with a friend to invite them onto albms.net.
      </p>

      <div>
        <img src='https://placehold.it/256x256' />
      </div>

      <div className="px-4">
        <h2 className="my-6 text-2xl font-bold text-center">
          Invite your friends and you'll both get £5
        </h2>
        <p className="text-sm text-center text-gray-500">
          Share your link with everyone you'd like to invite and
          we'll give you and them £5 (as long as they sign up and make a card payment within 30 days).
        </p>
      </div>

      <button>
        Share your invite
      </button>
    </div>
  )
}

export {
  ReferralPage
}