import { useEffect, useRef, useState } from "react"
import { Backend } from "../client/backend"
import { RatingItemContainer } from "../component/feed_items"
import { FaSort, FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa"
import { Loading } from "../util/loading"
import { useNavigate, useParams } from "react-router-dom"
import { NothingLeftContainer } from "../component/run_out_of_stuff"

const DefaultPredicate = (items: Array<any>) => {
  return items.filter((i) => !i.is_archived)
}

const sorts = [
  'REVIEWED',
  'TITLE',
  'RATING',
  'POPULARITY',
]

const RatingPage = ({ predicate = DefaultPredicate }) => {
  const { username, page } = useParams()
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [lastRequestedPageIndex, setLastRequestedPageIndex] = useState<any>(null);
  const loaderRef = useRef(null)
  const observer = useRef<any>(null)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [sort, setSort] = useState(sorts[0])
  const [asc, setAsc] = useState(false)
  const [total, setTotal] = useState<any>(undefined)
  const navigate = useNavigate()

  const loadMore = () => {
    if (loading) return;

    setLoading(true)

    if (username) {
      Backend.getFeedRatings(username, parseInt(page))
        .then((response) => response.json())
        .then((obj) => {
          const results = obj.results || []
          if (results.length === 0) {
            setHasMoreResults(false)
          }
          setResults((prev: any) => [...results])
          setTotal(obj.total)
        })
        .finally(() => {
          setLoading(false)
          if (loaderRef.current && hasMoreResults) {
            observer.current.observe(loaderRef.current)
          }
        })
    }
  }

  useEffect(() => {
    loadMore()
  }, [page])

  const toggleSort = (s) => {
    setSort(s)

    switch (s) {
      case 'RATING':
        setAsc(true)
        break;
      default:
        setAsc(false)
        break;
    }
  }

  return (
    <>
      <div className="flex flex-row gap-1 items-center justify-end mx-4">
        <div className="dropdown dropdown-end">
          <div tabIndex={0} role="button" className="btn btn-xs m-1">
            Sort By <FaSort />
          </div>
          <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
            {
              sorts
                .map((sortType) =>
                  <li key={sortType}>
                    <a className={`capitalize ${sort === sortType && 'font-bold'}`} onClick={() => toggleSort(sortType)}>
                      {sortType.toLocaleLowerCase()}
                    </a>
                  </li>)
            }
          </ul>
        </div>
        <div className="flex flex-col items-center">
          <button
            onClick={() => setAsc(!asc)}
            className="btn btn-xs">{!asc ? 'Ascending' : 'Descending'}</button>
        </div>
      </div>

      <div className="flex flex-row justify-around my-4">
        <div className='grid grid-cols-3 sm:grid-cols-5 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 justify-around'>
          <RatingItemContainer
            asc={asc}
            sort={sort}
            showDetails
            showMiniRating
            small
            results={results} />
        </div>

      </div>

      <div className="flex flex-col items-center mb-16">
        {
          total ? (
            <div className="flex flex-row gap-1">
              {
                Array.from({ length: Math.ceil(total / 50) }, (_, index) => index + 1)
                  .map((i) => (
                    <button onClick={() => navigate(`/ratings/${username}/page/${i}`)} className={`btn btn-xs ${(parseInt(page) || 1) == i && 'font-bold btn-disabled btn-ghost'}`}>
                      {i}
                    </button>
                  ))
              }
            </div>
          ) : (
            <button onClick={() => navigate(`/ratings/${username}/page/${(parseInt(page) || 1) + 1}`)} className="btn btn-xs">Next Page</button>
          )
        }
      </div >

      <div className='flex flex-row w-full justify-around my-2 text-gray-600'>
        {loading && (
          <>
            <Loading />
          </>
        )}
      </div>
    </>
  )
}

export {
  RatingPage
}