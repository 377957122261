import { useEffect, useRef, useState } from "react"
import { Backend } from "../client/backend"
import { FeedItemContainer } from "../component/feed_items"
import { Loading } from "../util/loading"
import { NothingLeftContainer } from "../component/run_out_of_stuff"

const DefaultPredicate = (items: Array<any>) => {
  return items
    // .filter((i) => i.content.length > 0)
    .filter((i) => !i.is_archived)
    .slice(0,6)
}

const DiscoverPageWithInfiniteScroll = ({ predicate = DefaultPredicate }) => {
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [lastRequestedPageIndex, setLastRequestedPageIndex] = useState<any>(null);
  const loaderRef = useRef(null)
  const observer = useRef<any>(null)
  const [hasMoreResults, setHasMoreResults] = useState(true)

  const loadMore = () => {
    if (loading || pageIndex === lastRequestedPageIndex) return;
    setLoading(true)
    setLastRequestedPageIndex(pageIndex);

    Backend.getDiscoverFeed(pageIndex)
      .then((response) => response.json())
      .then((obj) => {
        if (obj.length === 0) {
          setHasMoreResults(false)
        } else {
          setResults((prev: any) => predicate([...prev, ...obj]))
          setPageIndex(prev => prev + 1)
        }
      })
      .finally(() => {
        setLoading(false)
        if (loaderRef.current && hasMoreResults) {
          observer.current.observe(loaderRef.current)
        }
      })
  }

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMoreResults) {
        if (loaderRef.current) {
          observer.current.unobserve(loaderRef.current)
          loadMore();
        }
      }
    }, { threshold: 1.0 });

    if (loaderRef.current) {
      observer.current.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.current.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef, loading, hasMoreResults]);

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <>
      <div className='flex flex-col justify-around my-2'>
        <FeedItemContainer
          sort={undefined}
          asc={false}
          showMiniRating={false}
          results={results}
          responsive />
      </div>

      {
        !hasMoreResults && (
          <NothingLeftContainer />
        )
      }

      <div ref={loaderRef} className='flex flex-row mt-8 w-full justify-around my-2 text-gray-600'>
        {loading && (
          <>
            <Loading />
          </>
        )}
      </div>
    </>
  )
}

const DiscoverPage = ({ predicate = DefaultPredicate }) => {
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [lastRequestedPageIndex, setLastRequestedPageIndex] = useState<any>(null);
  const loaderRef = useRef(null)
  // const observer = useRef<any>(null)
  const [hasMoreResults, setHasMoreResults] = useState(true)

  const loadMore = () => {
    if (loading || pageIndex === lastRequestedPageIndex) return;
    setLoading(true)
    setLastRequestedPageIndex(pageIndex);

    Backend.getDiscoverFeed(pageIndex)
      .then((response) => response.json())
      .then((obj) => {
        if (obj.length === 0) {
          setHasMoreResults(false)
        } else {
          setResults((prev: any) => predicate([...prev, ...obj]))
          setPageIndex(prev => prev + 1)
        }
      })
      .finally(() => {
        setLoading(false)
        if (loaderRef.current && hasMoreResults) {
          // observer.current.observe(loaderRef.current)
        }
      })
  }

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <>
      <div className='flex flex-col my-2'>
        <FeedItemContainer
          sort={undefined}
          asc={false}
          showMiniRating
          results={results}
          responsive />
      </div>
    </>
  )
}

export {
  DiscoverPage,
  DiscoverPageWithInfiniteScroll
}