const MaxLen = 64 * 2

function sliceLines(content: string, MaxLines: number = 1) {
  const lines = (content || '').split('\n');
  const slicedLines: string[] = [];

  for (const line of lines) {
    if (slicedLines.length <= MaxLines) {
      slicedLines.push(line);
    } else {
      break;
    }
  }

  return slicedLines.join('\n');
}


function sliceContent(content: string, Len: number = MaxLen) {
  if (!content) {
    return content
  }

  // If content is already less than or equal to 64 characters, return as is
  if (content.length <= Len) {
    return content;
  }

  // Find the last period before the 64th character
  let lastPeriod = content.lastIndexOf('.', Len-1);
  if (lastPeriod > -1) {
    return content.slice(0, lastPeriod + 1) + '..';
  }

  // Find the last space before the 64th character to avoid breaking a word
  let lastSpace = content.lastIndexOf(' ', Len-1);
  if (lastSpace > -1) {
    return content.slice(0, lastSpace) + '...';
  }

  // If no suitable breaking point is found, return the first 64 characters
  return content.slice(0, Len);
}

export {
  sliceContent,
  sliceLines
}