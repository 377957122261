import { useEffect, useState } from "react"
import ConfettiExplosion from "react-confetti-explosion"
import { redirect, useNavigate, useSearchParams } from "react-router-dom"
import { Backend } from "../client/backend"
import { useAuth } from "@clerk/clerk-react"
import Session from "supertokens-auth-react/recipe/session";


const ActivatePage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [key, setKey] = useState<any>(undefined)
  const [showConfetti, setShowConfetti] = useState(false)

  useEffect(() => {
    if (searchParams.has('key')) {
      const key = searchParams.get('key')
      Backend.activate(key)
        .then((obj) => {
          console.log(obj)
          if (obj.ok) {
            setShowConfetti(true)
            setTimeout(() => {
              redirect('/')
            }, 1500)
          }
        })
    }
  }, [searchParams])

  useEffect(() => {
    document.title = 'albms.net - activate subscription'
  }, [document])

  return (
    <>
      <div className="mx-4">
        <h2 className="text-xl font-bold">Thank you for becoming a member!</h2>
        <div className="w-full flex flex-row justify-around">
          {showConfetti && <ConfettiExplosion />}
        </div>
        <p>
          We are activating your subscription.
        </p>
        <p>
          If there are any problems or this page does not
          redirect within 30 seconds please try again later or get in contact with us: felix@albms.net.
        </p>
      </div>
    </>
  )
}

export {
  ActivatePage
}