import React, { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate, useSearchParams } from "react-router-dom";

const ListConfirmation = () => {
  const [loading, setLoading] = useState()
  const [shareId, setShareId] = useState<any>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (searchParams.has('id')) {
      setShareId(searchParams.get('id'))
    }
  }, [searchParams])

  const handleShare = () => {
    if (navigator.share && shareId) {
      navigator.share({
        title: 'Share this list on albms.net',
        url: `https://albms.net/view/list/${shareId}`,
        text: 'Check out my albums list on albms.net!'
      }).then(() => { })
        .catch(console.error);
    } else {
      // copy to clipboard.
      navigator.clipboard.writeText(`https://albms.net/view/list/${shareId}`)
    }
  }

  return (
    <>

      <div className="m-4">
        <div className="flex flex-row justify-around">
          <h2 className="text-4xl font-bold my-8">List Created 🎉</h2>
        </div>

        <div className="flex flex-row justify-around w-full">
          <ConfettiExplosion
            force={0.8}
            particleCount={300} />
        </div>

        <div>
          <div className="flex flex-col my-2">
            <p className="text-lg">
              Looks good! Thanks for sharing your list.
            </p>
            <div className="my-2 text-sm">
              <p className="text-sm">
                Help us shape the future of albms.net &mdash; contribute your feedback by <a className='hover:cursor-pointer text-blue-500 font-bold hover:underline' href='https://forms.gle/jU5BEFyj4KvG3UKP9' target='_blank'>submitting this survey</a>.
              </p>
            </div>
          </div>

          <div>
            <div className="flex flex-col gap-2 my-8">
              {shareId && <div className="flex flex-row justify-around">
                <button onClick={handleShare} className="btn btn-success btn-wide">
                  Share It!
                </button>
              </div>}
              <div className="flex flex-row justify-around">
                <button onClick={() => navigate('/list')} className="btn btn-wide">
                  Create Another
                </button>
              </div>
              {shareId && <div className="flex flex-row justify-around">
                <button onClick={() => navigate(`/view/list/${shareId}`)} className="btn btn-ghost btn-sm">
                  View my list
                </button>
              </div>}
            </div>
          </div>
        </div >
      </div >
    </>
  )
}

export {
  ListConfirmation
}