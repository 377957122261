import { useEffect, useState } from "react"
import { Backend } from "../client/backend"
import { Album, AlbumOfTheWeekWizard, SelectedAlbum } from "../component/misc_overlay"
import { FaDice } from "react-icons/fa"
import { useSessionContext } from "supertokens-auth-react/recipe/session"
import { Loading } from "../util/loading"
import { useNavigate } from "react-router-dom"
import { RatingFeedItemContainer } from "../component/rating_feed_items"
import { IoSparkles } from "react-icons/io5"
import { LinkTo } from "./list"
import { AddToListDropdown } from "./view_album"

const AlbumSelection = ({ username, reRollClicked, continueClicked, reRollCount, albumSelection }) => {
  const [selected, setSelected] = useState<any>()

  useEffect(() => {
    if (albumSelection) {
      setSelected((albumSelection || []).slice(0, 1)[0])
    }
  }, [albumSelection])

  if (selected) {
    return (
      <div>
        <div className='flex flex-col items-center justify-around w-full'>
          <div className="flex flex-col my-2">
            <h2 className="text-lg leading-relaxed">
              <span className="font-bold text-2xl">{selected.title}</span> by {selected.artist}
            </h2>
          </div>

          <RatingFeedItemContainer
            small
            showMiniRating
            results={[selected]} />
        </div>

        <div className="flex flex-col items-center my-4">
          <div className="flex flex-col gap-2 items-center">
            <div className="flex flex-row gap-2 justify-around">
              <button onClick={() => continueClicked(selected)} className="btn btn-success btn-sm">
                <>View this album</>
              </button>
              <AddToListDropdown albms_id={selected.albms_id} username={username} />
            </div>
            <button disabled={reRollCount == 0} onClick={reRollClicked} className="btn btn-sm btn-primary">
              <>Re-roll ({reRollCount}) <span className="text-xl"><FaDice /></span></>
            </button>
          </div>

          <div className="my-8">
            <p className="text-xs text-gray-600">Any feedback? Please fill out <LinkTo value={'this survey'} to='https://forms.gle/jU5BEFyj4KvG3UKP9' target='_blank' />
               to help us improve this feature</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

const RecentlyInteractedAlbumsCarousel = ({ username }) => {
  const [data, setData] = useState<any>([])

  useEffect(() => {
    Backend.aotwSample(username).then((obj) => {
      if (obj) {
        setData(obj.results)
      }
    })
  }, [])

  // FIXME if < 3 ask to do ratings or smth

  if (data) {
    return (
      <div>
        <div className="flex flex-col items-center my-4">
          <div>
            <div className='grid grid-cols-3 w-full'>
              <RatingFeedItemContainer
                small
                showMiniRating
                results={(data || []).slice(0, 9)} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

const RE_ROLLS = 8

const LoadingContainer = () => {
  const [msgIndex, setMsgIndex] = useState(0);
  const [msg, setMsg] = useState('Sorry this might take some time ...')

  const messages = [
    'Loading, please wait...',
    'Still working on it...',
    'Almost there...',
    'Okay this is taking a while',
    'Probably a bit longer than expected',
    'If you are still waiting it can take this long!',
    "I hope it's worth it",
    'Something spicy',
    'Maybe a bit of Coldplay',
    'Nickelback...',
    'Kanye West?',
    'idk',
    'Like and subscribe',
    'Buy a membership!',
    'Tweet @AlbmsDotNet'
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMsgIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 2000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount

  }, []); // Empty dependency array to run the effect once when the component mounts

  useEffect(() => {
    setMsg(messages[msgIndex]);
  }, [msgIndex]);

  return (
    <div className="my-8 text-center">
      <Loading />
      <p className="text-xs text-gray-600 my-4">
        {msg}
      </p>
    </div>
  )
}

const ShufflePage = () => {
  const [loading, setLoading] = useState(false)
  const [albumSelection, setAlbumSelection] = useState<any>(undefined)
  const [reRollCount, setReRollCount] = useState(RE_ROLLS)
  const session = useSessionContext() as any
  const [profile, setProfile] = useState<any>()
  const navigate = useNavigate()

  useEffect(() => {
    Backend.myStats()
      .then((obj) => {
        setProfile(obj)
      })
  }, [])


  const onDiceRollClicked = (username: string) => {
    setAlbumSelection(undefined)

    Backend.aotwShuffle(username)
      .then((obj) => {
        if (obj) {
          setAlbumSelection(obj.results)
        }
        setLoading(false)
      })
    setLoading(true)
  }

  const onAlbumSelected = (selection) => {
    // TODO:
    // 1. go to review page.
    navigate(`/view/album/${selection.albms_id}`)
  }

  const reRollClicked = (username: string) => {
    if (reRollCount <= 0) {
      return
    }

    setAlbumSelection(undefined)

    Backend.aotwShuffle(username)
      .then((obj) => {
        if (obj) {
          setAlbumSelection(obj.results)
        }
        setLoading(false)
      })
      .finally(() => {
        setReRollCount((prev) => prev - 1)
      })
    setLoading(true)
  }

  if (!session.doesSessionExist || profile === undefined) {
    return (
      <>
        <div className="flex flex-col items-center my-16">
          <h2 className="text-2xl font-bold">albms.net &mdash; shuffle</h2>
          <p className="my-2 text-lg">
            You need an albms.net account to use this feature!
          </p>

          <div className="flex flex-col items-center my-8">
            <button className="btn btn-wide btn-success shadow-xl " onClick={() => navigate('/auth')}>
              Sign In / Sign Up <span className="text-xs text-yellow-500"><IoSparkles /></span>
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="flex flex-col items-center mx-4">
      <div className="text-center">
        <h2 className="text-2xl font-bold">albms.net &mdash; shuffle</h2>
        <p className="text-lg text-gray-600 my-2">
          We will pick an album for you based on your recent interactions on albms.net.
        </p>

        {
          albumSelection ? (
            <AlbumSelection username={profile?.username} continueClicked={onAlbumSelected} reRollClicked={() => reRollClicked(profile?.username)} reRollCount={reRollCount} albumSelection={albumSelection} />
          ) : (
            <>
              {
                loading ? <LoadingContainer /> : <RecentlyInteractedAlbumsCarousel username={profile?.username} />
              }

              <div className="flex flex-row justify-around my-4 mb-8">
                {!loading && <button disabled={loading} onClick={() => onDiceRollClicked(profile?.username)} className="btn btn-wide btn-primary">
                  <>I'm feeling lucky <span className="text-2xl"><FaDice /></span></>
                </button>}
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export {
  ShufflePage
}