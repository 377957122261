import { useNavigate } from "react-router-dom"
import moment from "moment"
import { sliceContent, sliceLines } from "../util/random"
import { ProfileBadge } from "./profile_badge"
import { ThumbnailFeedItem } from "./thumbnail_feed_item"
import { ReviewContainer } from "../pages/review"
import { useEffect, useState } from "react"


const sortBy = (sort) => {
  return ({
    'TITLE': (a, b) => a.album_title.toLowerCase().localeCompare(b.album_title.toLowerCase()),
    'RATING': (a, b) => a.rating - b.rating,
    'REVIEWED': (a, b) => moment(a.created_at).isBefore(moment(b.created_at)),
    'POPULARITY': (a, b) => a.popularity_rank - b.popularity_rank,
    'SHUFFLE': () => Math.random() - 0.5,
  }[sort]) || ((a) => a)
}

const RatingItemContainer = ({ asc = true, sort = undefined, showMiniRating = false, results, small = false, showAlbumCover = true, showDetails = false }: any) => {
  if (!sort) {
    return (
      <>
        {
          results
            .map((result: any, index: any) => <FeedItem
              clickable={`/view/album/${result.albms_id}`}
              showMiniRating={showMiniRating}
              small={small}
              showAlbumCover={showAlbumCover}
              showAlbumDetail={showDetails}
              key={`feed-item-${index}-${result.author_username}-${result.albms_id}`} {...result} />)
        }
      </>
    )
  }

  const [sortedResults, setSortedResults] = useState([...results])

  useEffect(() => {
    if (asc) {
      setSortedResults(results
        .sort((a, b) => sortBy('TITLE')(a, b))
        .sort((a, b) => sortBy(sort)(a, b))
      )
    } else {
      setSortedResults(results
        .sort((a, b) => sortBy('TITLE')(a, b))
        .sort((a, b) => sortBy(sort)(a, b))
        .reverse())
    }
  }, [results, asc, sort])

  return (
    <>
      {
        sortedResults
          .map((result: any, index: any) => <FeedItem
            clickable={`/view/album/${result.albms_id}`}
            showAlbumCover={showAlbumCover}
            showMiniRating={showMiniRating}
            small={small}
            showAlbumDetail={showDetails}
            key={`feed-item-${index}-${result.author_username}-${result.albms_id}`} {...result} />)
      }
    </>
  )
}

const FeedItemContainer = ({ sort = undefined, showMiniRating = false, results, small = false, showAlbumCover = true, showDetails = true, responsive = false }: any) => {
  if (!sort) {
    return (
      <>
        {
          results
            // .sort((a, b) => sortBy(sort)(a, b))
            .map((result: any, index: any) => <FeedItem
              showDetails={showDetails}  
              showMiniRating={showMiniRating}
              small={small}
              showAlbumCover={showAlbumCover}
              responsive={responsive}
              key={`feed-item-${index}-${result.author_username}-${result.albms_id}`} {...result} />)
        }
      </>
    )
  }

  return (
    <>
      {
        results
          .sort((a, b) => sortBy(sort)(a, b))
          .map((result: any, index: any) => <FeedItem
            showDetails={showDetails}
            showAlbumCover={showAlbumCover}
            showMiniRating={showMiniRating}
            small={small}
            responsive={responsive}
            key={`feed-item-${index}-${result.author_username}-${result.albms_id}`} {...result} />)
      }
    </>
  )
}

const ViewReviewModal = ({ external_id }) => {
  return (
    <div className="modal-box px-0 md:px-8">
      <form method="dialog">
        <button className="btn btn-sm btn-circle text-xl btn-ghost absolute right-2 top-2">✕</button>
      </form>

      <ReviewContainer withEditControl={false} id={external_id} />
    </div>
  )
}

const FeedItem = ({ external_id, album_title, albms_id, artist_name, content, author_username, created_at, listened_at, rating, small, clickable = `/review/${external_id}`, showMiniRating = false, showAlbumCover = true, responsive = false, showDetails = true, showAlbumDetail = false }: any) => {
  const navigate = useNavigate()

  const onClick = () => {
    if (!responsive) {
      navigate(clickable)
    } else {
      (document.getElementById(`reviewModal_${external_id}`) as any).showModal()
    }
  }

  if (small) {
    return (
      <ThumbnailFeedItem
        showAlbumDetail={showAlbumDetail}
        album_title={album_title}
        clickable={clickable}
        albms_id={albms_id}
        artist_name={artist_name}
        rating={rating}
        showMiniRating={showMiniRating}
        showAlbumCover={showAlbumCover}
      />
    )
  }

  return (
    <ThumbnailFeedItem
      album_title={album_title}
      albms_id={albms_id}
      artist_name={artist_name}
      rating={rating}
      showMiniRating={showMiniRating}
      clickable={clickable}
      showAlbumDetail={false}
      showAlbumCover={showAlbumCover}>

      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between ml-2">
          <div className="flex flex-col w-full">
            { showDetails && <div onClick={onClick} className={`my-1 ${clickable && 'hover:cursor-pointer'}`}>
              <h2 className="text-lg font-bold">{album_title}</h2>
              <h3 className="text-sm font-normal">{artist_name}</h3>
            </div> } 

            <div className="flex flex-col justify-around">
              <span className="flex flex-row items-center mt-1"><ProfileBadge username={author_username} /></span>
              <div className="flex flex-col self-start">
                <div data-tip={`Listened to on the ${moment(listened_at).format('Do MMMM, YYYY')}.`} className='flex flex-col tooltip tooltip-bottom tooltip-xs mx-1'>
                  <p className='text-xs'>Posted {moment(created_at).fromNow()}. {((moment(listened_at).add(1, 'hour')).isBefore(moment(created_at))) && <>Listened to {moment(listened_at).fromNow()}.</>}</p>
                </div>
              </div>
            </div>

            <div className={`my-0 w-full flex ${clickable && 'hover:cursor-pointer'} flex-row whitespace-break-spaces`}>
              <div id='reviewContent' className="w-full">
                <p className="fade" onClick={onClick}>
                  {sliceLines(sliceContent(content))}
                </p>
                <div onClick={() => navigate(clickable)} className="flex flex-row justify-between my-2 gap-2">
                  <button className="btn btn-xs">Read Review</button>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      {
        responsive && (
          <dialog id={`reviewModal_${external_id}`} className="modal modal-bottom sm:modal-middle">
            <ViewReviewModal external_id={external_id} />
          </dialog>
        )
      }
    </ThumbnailFeedItem>
  )
}


export {
  FeedItemContainer,
  FeedItem,
  RatingItemContainer
}