import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSessionContext } from "supertokens-auth-react/recipe/session"
import { Loading } from "./loading"

const RedirectIfNotSignedIn = ({ children }) => {
  const navigate = useNavigate()
  const session = useSessionContext() as any
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  // on ANY page we must redirect to onboarding flow
  // if we have not had our status set.
  useEffect(() => {
    if (!session.doesSessionExist) {
      navigate('/auth')
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <>
      {
        loading ? (
          <Loading />
        ) : (
          <>
            {children && children}
          </>
        )
      }
    </>
  )
}

export {
  RedirectIfNotSignedIn
}