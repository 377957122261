import { FaSpider } from "react-icons/fa";

const NothingLeftContainer = () => {
  const onScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <div className='flex flex-row w-full justify-around my-2'>
      <div className="flex flex-col items-center gap-2">
        <p className="flex flex-row  gap-1 items-center text-xs my-4">
          <span><FaSpider /></span> We've run out of stuff to show you!
        </p>
        <div>
          <button onClick={onScrollTop} className="btn btn-success btn-sm">
            Scroll to Top
          </button>
        </div>
      </div>
    </div>
  )
}

export {
  NothingLeftContainer
}