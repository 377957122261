import { useEffect, useState } from "react"
import { Backend } from "../client/backend"
import { FaRecycle } from "react-icons/fa"
import { IoShuffle, IoSwapHorizontal } from "react-icons/io5"
import { RatingContainer } from "../component/rating"
import { NumericRatingInput, StarRatingInput } from "./submit_page"

/*

random idea dump:

- click shows controls: rate or skip.
- on hover we show title, artist on side?
- some interesting notes on the album?
- maybe a rating of how likely you are to like it based on ur current likes?

ask:
- either ask for genre
- ask for an artist.

*/

const AlbumTile = ({ albms_id }) => {
  const [active, setActive] = useState(false)

  return (
    <div
      onMouseLeave={() => setActive(false)}
      onMouseOver={() => setActive(true)}
      className="flex flex-row relative">
      <img src={Backend.imageFor(albms_id, 'md')} />

      {active &&
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-end">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <StarRatingInput size={32} rating={5} setRating={() => { }} />
            </div>

            <div className="text-white text-center">
              <button className="bg-blue-500 text-white px-4 py-2 rounded"><IoSwapHorizontal /></button>
            </div>
          </div>
        </div>}
    </div>
  )
}

// search pane is a grid of albums
// by clicking on an album you select it
// you can either pass on an album or rate it
// either of those actions will remove it from the grid.
const SearchPane = ({ handleAlbumClicked }) => {
  const [results, setResults] = useState<any>([])

  // TODO:
  // - shuffle must return results you have not interacted with before
  // - first grid of results must be varied
  // - following grids are loosely based on last N suggestions.
  // - repalcements must be similar to ???

  useEffect(() => {
    // store keyed by albms id.
    Backend.shuffle()
      .then((obj) => {
        setResults(obj.Results || [])
      })
  }, [])

  return (
    <div className="flex flex-col items-center justify-around">
      <div className="grid grid-cols-3 gap-2">
        {
          results
            .slice(0, 9)
            .map(({ title, artist, albms_id }) => (
              <div onClick={() => handleAlbumClicked(albms_id)}>
                <AlbumTile albms_id={albms_id} />
              </div>
            ))
        }
      </div>
    </div>
  )
}

const ReviewPane = ({ selectedAlbum }) => {
  return (
    <div className="mx-4">
      <h2>Selected album</h2>
      <p>{selectedAlbum}</p>
    </div>
  )
}

const ExplorePage = () => {
  const [selectedAlbum, setSelectedAlbum] = useState<any>()

  const handleAlbumClicked = (id: string) => {
    // focus album.
    setSelectedAlbum(id)
  }

  return (
    <>
      <div className="flex flex-row">
        <SearchPane handleAlbumClicked={handleAlbumClicked} />

        <ReviewPane selectedAlbum={selectedAlbum} />
      </div>
    </>
  )
}

export {
  ExplorePage
}