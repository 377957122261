import { useAuth } from "@clerk/clerk-react"
import { Backend } from "../client/backend"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { IoSparklesSharp } from "react-icons/io5"
import { LinkTo } from "../pages/list"
import { ScribbleSearchWithAI, ScribbleSearch } from "../pages/submit_page"
import Session from "supertokens-auth-react/recipe/session";


const Search = ({ setSelectedAlbumMBID, tooltip, initSearchVal = undefined, selectionText = 'View' }) => {
  const navigate = useNavigate()
  const [credits, setCredits] = useState(0)
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    Backend.searchCredits().then((obj: any) => {
      if (obj) {
        setCredits(obj.limit - obj.count)
      }
    })
  }, [])

  const onSearchResolved = () => {
    Backend.searchCredits().then((obj: any) => {
      if (obj) {
        setCredits(obj.limit - obj.count)
      }
    })
  }

  const tabs = [
    () => <span className="flex flex-row">Specific search</span>,
    () => <>
      <span className="flex flex-row gap-1">
        Smart search <span className="text-yellow-400 mt-1"><IoSparklesSharp /></span>
      </span>
    </>,
  ]

  return (
    <>
      <div>
        <div>
          <div>
            <div role="tablist" className="w-full tabs tabs-boxed">
              {
                tabs.map((t, i) =>
                  <a onClick={() => setActiveTab(i)}
                    role="tab" className={`flex flex-row tab ${activeTab == i && 'tab-active'}`}>{t()}</a>)
              }
            </div>

            {
              (activeTab == 1) && (
                <>
                  {
                    credits > 0 ? (
                      <>
                        <ScribbleSearchWithAI
                          title='What kind of thing are you looking for?'
                          placeholder='The beatles best albums'
                          inspiration={false}
                          initSearchVal={initSearchVal}
                          tooltip={tooltip}
                          onSearchResolved={onSearchResolved}
                          selectionText={selectionText}
                          setSelectedAlbumMBID={setSelectedAlbumMBID} />
                        <h3><span className="font-bold">{credits} searches</span> remaining this month.</h3>
                        <p className="text-sm">
                          We use search credits as these incur greater cost to us than a specific search. You can get more searches
                          by upgrading to <LinkTo to='/pro' value={'PRO or Patron'} />.
                        </p>
                      </>
                    ) : (
                      <div className="alert alert-warning flex flex-col items-center justify-around my-8">
                        <p className="font-bold text-lg">You have used your search quota for this month</p>
                        <p className='text-center'>
                          You can upgrade your account to both support the development of albms.net and receive more AI-assisted searches!
                          Find out more on the <LinkTo to='https://albms.net/pro' value='membership page' />.
                        </p>
                        <p>
                          Otherwise, you can use 'Specific search' until your quota resets next month.
                        </p>
                      </div>
                    )
                  }
                </>
              )
            }
            {
              activeTab == 0 && (
                <ScribbleSearch
                  initSearchVal={initSearchVal}
                  title='Search for an album'
                  selectionText={'View'}
                  tooltip={tooltip}
                  setSelectedAlbumMBID={setSelectedAlbumMBID} />
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

export {
  Search
}
