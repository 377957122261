import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Search } from "../component/search"

const SearchPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [initSearchVal, setInitSearchVal] = useState<any>(undefined)

  useEffect(() => {
    if (searchParams.get('q')) {
      setInitSearchVal(searchParams.get('q'))
    }
  }, [searchParams])

  useEffect(() => {
    document.title = 'albms.net - search'
  }, [document])

  const onSelect = ({ albmsId }) => {
    navigate(`/view/album/${albmsId}`)
  }

  return (
    <div className="mx-4">
      <Search
        initSearchVal={initSearchVal}
        tooltip='Search for an album'
        selectionText="View"
        setSelectedAlbumMBID={onSelect} />
    </div>
  )
}

export {
  SearchPage
}