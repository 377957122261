import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const ErrorPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'albms.net - error'
  }, [])

  return (
    <div>
      <h2 className="text-4xl font-bold">Something went wrong</h2>
      <p>
        We couldn't find what you were looking for.
      </p>

      <div className="flex flex-row justify-around my-8">
        <div className="w-1/2">
          <img alt='a sad cd case thing' src='/sad-cd.png' />
        </div>
      </div>
    </div>
  )
}

export {
  ErrorPage
}