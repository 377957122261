import { Badge, PatronBadge, ProBadge } from "../component/profile_badge"
import { useEffect } from "react"
import { SessionAuth } from "supertokens-auth-react/recipe/session"

const Bucket = ({ tier, href = undefined, cost = undefined, children = undefined }) => {
  const onClick = () => {
    window.open('https://patreon.com/user?u=112514836&utm_medium=clipboard_copy&utm_source=copyLink&utm_campaign=creatorshare_creator&utm_content=join_link', '_blank')
  }

  return (
    <div className="w-full mb-8 md:mb-0 md:w-1/3">
      <div className="z-0 flex flex-row justify-around">
        {tier == 'FREE' && <Badge size='xl'>FREE</Badge>}
        {tier == 'PRO' && <ProBadge size='xl' />}
        {tier == 'PATRON' && <PatronBadge size='xl' />}
      </div>
      <div className="-my-2 z-10 flex flex-col gap-4 p-4 bg-neutral rounded-lg">
        {children}
      </div>
      {
        cost && (
          <div className="flex flex-col">
            <div className="flex flex-row justify-around w-full p-4">
              <div className="flex flex-col items-end">
                <span className="text-4xl"><span className="text-lg">GBP</span> £{cost.toFixed(2)}</span>
                <span className="">PER MONTH</span>
              </div>
            </div>

            <div className="flex flex-row justify-around">
              <a href={href} className="btn btn-success btn-sm lemonsqueezy-button">
                Become a {tier}
              </a>
            </div>
          </div>
        )
      }
    </div >
  )
}

const ProPage = () => {
  useEffect(() => {
    document.title = 'albms.net - support us'
  }, [document])

  return (
    <>
      <div className="mx-4 my-8">
        <h2 className="text-2xl font-bold">Support albms.net</h2>
        <p>
          <span className="font-bold">Thank you for your interest in supporting albms.net.</span> We have a few plans that are available if you want to support the development of albms.net. All of these choices are available in yearly and monthly plans. The yearly plans have a discount.
        </p>
      </div>

      <div className="flex flex-col flex-col-reverse mb-16 md:flex-row justify-evenly gap-2 mx-4">
        <Bucket tier='FREE'>
          <ul>
            <li>All accounts on albms.net are under the free tier</li>
          </ul>
        </Bucket>

        <Bucket href='https://albmsnet.lemonsqueezy.com/checkout/buy/30302ee6-cb78-4299-b457-cdf049804118?embed=1&media=0' cost={2.00} tier='PRO'>
          <ol className="text-relaxed gap-2 flex flex-col">
            <li>100 AI-assisted list generations per month</li>
            <li>No advertisements</li>
            <li>Personalised stats visible on your profile page</li>
          </ol>
        </Bucket>

        <Bucket href='https://albmsnet.lemonsqueezy.com/checkout/buy/150bf396-09ea-400d-ad50-598e8fc66dc8?embed=1&media=0' cost={3.00} tier='PATRON'>
          <ol className="text-relaxed gap-2 flex flex-col">
            <li>Everything in PRO</li>
            <li>500+ AI-assisted list generations per month</li>
            <li>Your profile on this page and our patron page</li>
            <li>Optionally join the albms.net roadmap to have your say</li>
            <li>Eternal thanks from the albms.net team</li>
          </ol>
        </Bucket>
      </div>
    </>
  )
}

export {
  ProPage
}