import { useAuth } from "@clerk/clerk-react"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Backend } from "../client/backend"
import { Loading } from "../util/loading"
import { FaTrash, FaBars, FaHeart, FaShare, FaBox, FaList, FaThLarge, FaThList, FaStar, FaStarOfLife } from "react-icons/fa"
import { ImageWithErr } from "../component/image"
import { GenericRatingContainer, RatingContainer } from "../component/rating"
import { Rating } from "react-simple-star-rating"
import { ProfileBadge } from "../component/profile_badge"

const ListItemThumbnail = ({ title, index, artist, albms_id, username }: any) => {
  const [rating, setRating] = useState(0)

  useEffect(() => {
    if (username) {
      Backend.getAlbumRating(albms_id, username)
        .then((obj) => {
          if (obj) {
            setRating(obj.rating)
          }
        })
    }
  }, [username, albms_id])

  return (
    <div className="list-item-card-wrapper">
      <div className={`flex flex-col justify-around gap-2 list-item-card`}>
        <ImageWithErr
          classNames="rounded-none"
          alt={`covert art for ${artist} - ${title}`}
          src={Backend.imageFor(albms_id, 'md')} />
      </div>
    </div>
  )
}

const ViewListItemGrid = ({ title, rank, artist, albms_id, username, onDelete }: any) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/view/album/${albms_id}`)
  }

  // FIXME(FELIX): use the feed item for this.
  return (
    <div className='flex flex-col justify-around w-full'>
      <div className='flex flex-row justify-around'>
        <div className={`flex justify-start w-1/1`}>
          <div onClick={handleClick} className={`hover:cursor-pointer self-center`}>
            <ImageWithErr src={Backend.imageFor(albms_id)} alt={`cover art for ${artist} - ${title}`} />
            <span style={{
              marginTop: '-16px'
            }} className="flex flex-row justify-around">
              <div>
                <GenericRatingContainer generic size='small' rating={rank + 1} />
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const ViewListItem = ({ title, rank, artist, albms_id, username, onDelete }: any) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/view/album/${albms_id}`)
  }

  return (
    <div className='flex flex-row justify-around gap-2 w-full mb-8'>
      <div onClick={handleClick} className="flex flex-col items-center w-1/4 hover:cursor-pointer self-center">
        <ImageWithErr
          alt={`covert art for ${artist} - ${title}`}
          src={Backend.imageFor(albms_id)} />
        <span style={{
          marginTop: '-16px'
        }} className="flex flex-row justify-around">
          <div>
            <GenericRatingContainer generic size='small' rating={rank + 1} />
          </div>
        </span>
      </div>

      <div className='w-3/4 p-2'>
        <h2 className='font-bold'>{title}</h2>
        <h3>{artist}</h3>
      </div>
    </div>
  )
}

const GridView = ({ obj }) => {
  return (
    <div className="flex flex-row justify-around w-full">
      <div className='grid gap-4 grid-cols-3 sm:grid-cols-5 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 justify-around'>
        {(obj.albums || [])
          .sort((a, b) => a.rank - b.rank)
          .map((item: any) => <ViewListItemGrid key={item.albms_id} username={obj.username} {...item} />)}
      </div>
    </div>
  )
}

const ListView = ({ obj }) => {
  return (
    <>
      {(obj.albums || [])
        .sort((a, b) => a.rank - b.rank)
        .map((item: any) => <ViewListItem key={item.albms_id} username={obj.username} {...item} />)}
    </>
  )
}

const ViewListPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [showRatings, setShowRatings] = useState(false)
  const [gridMode, setGridMode] = useState(true)
  const [obj, setList] = useState<any>()

  useEffect(() => {
    if (id) {
      Backend.getListById(id)
        .then((obj: any) => {
          setList(obj)
        })
    }
  }, [id])

  if (!obj) {
    return <Loading />
  }

  const handleToggleGridOrList = () => {
    setGridMode(!gridMode)
  }

  const handleShare = () => {
    if (navigator.share && id) {
      navigator.share({
        title: 'Share this list on albms.net',
        url: `https://albms.net/view/list/${id}`,
        text: 'Check out my albums list on albms.net!'
      }).then(() => { })
        .catch(console.error);
    }
  }

  return (
    <>
      <div className="flex flex-row justify-between m-4">
        <div className="my-4 mb-2">
          <h2 className="text-2xl font-bold">{obj.title}</h2>
          <span><ProfileBadge username={obj.username} /></span>
          {obj.description && <p>{obj.description}</p>}
        </div>

        <div className="flex flex-row gap-2 items-center">
          <button className="btn btn-xs btn-ghost" onClick={handleToggleGridOrList}>
            {!gridMode ? <FaThLarge /> : <FaThList />}
          </button>

          <button className="btn btn-xs btn-success" onClick={handleShare}>
            <FaShare />
          </button>
        </div>
      </div>

      <div className="flex flex-col mx-2 my-4">
        {
          gridMode ? (
            <>
              <GridView obj={obj} />
            </>
          ) : (
            <>
              <ListView obj={obj} />
            </>
          )
        }
      </div>
    </>
  )
}

export {
  ViewListPage,
  ViewListItem,
  ListItemThumbnail
}