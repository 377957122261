import { useParams } from "react-router-dom"
import { ProfilePage } from "./profile"

const RoutingPage = () => {
  const { stuff } = useParams()

  if (!stuff) {
    return <></>
  }

  if (stuff.startsWith('@')) {
    return <ProfilePage userId={stuff.slice(1,)} />
  }

  return (
    <div className="m-4">
      <h2 className="text-4xl font-bold">Not found</h2>
      <p>
        We couldn't find what you were looking for.
      </p>

      <div className="flex flex-row justify-around my-8">
        <div className="w-1/2">
          <img loading='eager' alt='a sad cd case thing' src='/sad-cd.png' />
        </div>
      </div>
    </div>
  )
}

export {
  RoutingPage
}