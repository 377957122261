const ResultsContainer = ({ children }: any) => {
  return (
    <div className="flex flex-row overflow-y-auto mx-4">
      <div className="flex flex-row justify-evenly my-2">
        {children}
      </div>
    </div>
  )
}

export {
  ResultsContainer
}