import { useState, useEffect } from "react";
import { Backend } from "../client/backend";
import { RatingFeedItemContainer } from "../component/rating_feed_items";

const DefaultPredicate = (items: Array<any>) => {
  return items
}

const ArbitraryAlbumHorizontalFeed = ({ predicate = DefaultPredicate }) => {
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [lastRequestedPageIndex, setLastRequestedPageIndex] = useState<any>(null);

  const loadMore = () => {
    if (loading || pageIndex === lastRequestedPageIndex) return;
    setLoading(true)
    setLastRequestedPageIndex(pageIndex);

    Backend.getPopularFeed(pageIndex)
      .then((response: any) => response.json())
      .then((obj: any) => {
        setResults((prev: any) => predicate([...prev, ...(obj.ratings)]))
        setPageIndex(prev => prev + 1)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div className='flex flex-col w-full'>
      <div className="flex flex-row overflow-x-scroll">
        <RatingFeedItemContainer
          small
          showDetails
          showMiniRating={false}
          results={(results || []).slice(0, 12)} />
      </div>
    </div>
  )
}

const RatingFeed = ({ predicate = DefaultPredicate }) => {
  const [results, setResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [lastRequestedPageIndex, setLastRequestedPageIndex] = useState<any>(null);

  const loadMore = () => {
    if (loading || pageIndex === lastRequestedPageIndex) return;
    setLoading(true)
    setLastRequestedPageIndex(pageIndex);

    Backend.getRatingsFeed(pageIndex)
      .then((response: any) => response.json())
      .then((obj: any) => {
        setResults((prev: any) => predicate([...prev, ...(obj.ratings)]))
        setPageIndex(prev => prev + 1)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div className='flex flex-col w-full'>
      <div className="flex flex-row overflow-x-scroll">
        <RatingFeedItemContainer
          small
          showMiniRating={false}
          showDetails={true}
          results={(results || []).slice(0, 12)} />
      </div>
    </div>
  )
}

export {
  RatingFeed,
  ArbitraryAlbumHorizontalFeed
}