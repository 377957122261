import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LinkTo } from "./list"
import { Backend } from "../client/backend"
import { Loading } from "../util/loading"

// FIXME(FELIX):
// check if user has been setup. if not show them this page!
// if user has been setup (username set, etc)
const OnboardingPage = () => {
  const navigate = useNavigate()
  const [isAvailable, setIsAvailable] = useState<any>(false)
  const [username, setUsername] = useState<any>(undefined)
  const [debouncedUsername, setDebouncedQuery] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [registerLoading, setRegisterLoading] = useState(false)

  useEffect(() => {
    document.title = 'albms.net - onboarding'
  }, [])

  useEffect(() => {
    Backend.verifyProfile(debouncedUsername)
      .then((obj) => {
        if (obj) {
          setLoading(false)
          setIsAvailable(obj.ok)
        }
      })
  }, [debouncedUsername])

  useEffect(() => {
    setLoading(true)

    const timerId = setTimeout(() => {
      setDebouncedQuery(username);
    }, 125);

    return () => {
      clearTimeout(timerId);
    };
  }, [username]);

  const handleRegister = () => {
    setRegisterLoading(true)

    Backend.registerSession(debouncedUsername)
      .then((obj) => {
        if (obj.ok) {
          setRegisterLoading(false)
          // TODO some kind of success page.
          navigate('/')
        } else {
          alert('Something went wrong. Please try again later!')
        }
      })
  }

  return (
    <div className="mx-4">
      <h2 className="text-2xl font-bold">Welcome to albms.net!</h2>
      <p>
        We're glad to have you! Before you get started please choose a username to go by.
      </p>
      <p className="text-sm text-gray-600">
        This cannot be changed and will be used in your profile link, for example: <LinkTo value='albms.net/@felix' to={'https://albms.net/@felix'} />.
      </p>

      <div className="my-8">
        {/* on query timeout check if available */}
        <div className="flex flex-col">
          <p className="font-bold">Username</p>
          <p className="text-gray-600 text-xs mb-2">
            Username must be 3-32 characters long and may only contain alphanumeric characters, underscores, and dots
          </p>
          <input
            placeholder="hunter2" type='text'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input input-bordered input-primary w-full" />

          <div className="flex flex-row mb-1">
            {
              loading ? (
                <span className="flex text-gray-600 flex-row items-center text-sm gap-1 my-1">
                  <Loading size='xs' /> Loading
                </span>
              ) : (
                <>
                  {isAvailable ? <label className="text-xs text-green-500 font-bold">Available!</label>
                    : <label className="text-xs font-bold text-red-500">Not available</label>}
                </>
              )
            }

          </div>
        </div>

        <div className="flex flex-col items-center my-4">
          <button onClick={handleRegister} className="btn btn-success btn-xs" disabled={!isAvailable && !loading && !registerLoading}>
            Create Profile
          </button>
        </div>
      </div >

    </div >
  )
}

export {
  OnboardingPage
}